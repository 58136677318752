import axios from 'axios';
import URLs from '../../config/api-endpoints';
import { getAuthToken } from '../../utils/session';

const getSettings = () => {
  const token = getAuthToken();
  const url = URLs.SETTINGS.GET_SETTINGS;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default getSettings;
