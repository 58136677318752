import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getAllAnnouncements from '../../api/announcements/get-announcements';
import AnnouncementDialog from './announcement';

type Announcement = {
  _id: string;
  title: string;
  content: string;
  isArabic: boolean;
};

const AnnouncementMapper = () => {
  const userData = useSelector(
    (state: {
      user: {
        userType: string;
        userInfo: { [key: string]: string | [] | boolean | object };
        token: string;
        isJustLoggedIn: boolean;
      };
    }) => state.user,
  );
  const { userType } = userData;

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [openIndex, setOpenIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);

  const getLocalStorageDontShowAgain = () => {
    const dontShowAgainLocal = localStorage.getItem('dontShowAgain');
    if (dontShowAgainLocal) {
      return JSON.parse(dontShowAgainLocal);
    }
    return [];
  };

  const fetchAnnouncements = async () => {
    const dontShowIds = getLocalStorageDontShowAgain();
    const result = await getAllAnnouncements({ dontShowIds });
    setAnnouncements(result.data.announcements);
    dontShowIds.filter((item) => announcements.includes(item._id));

    dontShowIds.filter((id) => result.data.announcements.includes(id));

    if (result.data.announcements.length > 0) {
      setIsDialogOpen(true);
    }
  };

  const handleClose = (id, dontShowAgain = false) => {
    const dontShowAgainIds = localStorage.getItem('dontShowAgain');
    const dontShowIds = dontShowAgainIds ? JSON.parse(dontShowAgainIds) : [];
    if (dontShowAgain) {
      dontShowIds.push(id);
      localStorage.setItem('dontShowAgain', JSON.stringify(dontShowIds));
    }
    if (openIndex + 1 < announcements.length) {
      setOpenIndex((prevOpenIndex) => prevOpenIndex + 1);
    } else {
      setIsDialogOpen(false);
    }
  };

  useEffect(() => {
    if (userType !== 'ADMIN' && userType !== 'EMPLOYEE' && userType) {
      setTimeout(() => fetchAnnouncements(), 500);
    }
  }, [userData?.userType]);

  useEffect(() => {
    setForceFetch(true);
    return () => setForceFetch(false);
  }, []);

  return announcements.map(({ _id, title, content, isArabic }, index) => (
    <AnnouncementDialog
      isOpen={isDialogOpen && index === openIndex}
      onClose={handleClose}
      title={title}
      content={content}
      isArabic={isArabic}
      announcementId={_id}
      key={_id}
    />
  ));
};

export default AnnouncementMapper;
