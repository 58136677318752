// eslint-disable-next-line import/no-extraneous-dependencies
import { extendTheme as joyExtendTheme } from '@mui/joy/styles';
import { experimental_extendTheme as materialExtendTheme } from '@mui/material/styles';

const palette = {
  primary: {
    solidBg: '#db5759',
    solidBorder: '#f7635c',
    solidHoverBg: '#ff4d4d',
    solidHoverBorder: '#f7635c',
    solidActiveBg: '#db5759',
    solidActiveBorder: '#db5759',
    solidDisabledBg: '#fccfcf',
    solidDisabledBorder: '#fccfcf',
  },
  neutral: {
    solidBg: '#6c757d',
    solidBorder: '#6c757d',
    solidHoverBg: '#5c636a',
    solidHoverBorder: '#565e64',
    solidActiveBg: '#565e64',
    solidActiveBorder: '#51585e',
    solidDisabledBg: '#6c757d',
    solidDisabledBorder: '#6c757d',
    // btn-light
    softColor: '#000',
    softBg: '#f8f9fa',
    softBorder: '#f8f9fa',
    softHoverBg: '#f9fafb',
    softHoverBorder: '#f9fafb',
    softActiveBg: '#f9fafb',
    softActiveBorder: '#f9fafb',
    softDisabledBg: '#f8f9fa',
    softDisabledBorder: '#f8f9fa',
  },
  success: {
    solidBg: '#198754',
    solidBorder: '#198754',
    solidHoverBg: '#157347',
    solidHoverBorder: '#146c43',
    solidActiveBg: '#146c43',
    solidActiveBorder: '#13653f',
    solidDisabledBg: '#198754',
    solidDisabledBorder: '#198754',
  },
  danger: {
    solidBg: '#db5759',
    solidBorder: '#f7635c',
    solidHoverBg: '#ff4d4d',
    solidHoverBorder: '#f7635c',
    solidActiveBg: '#db5759',
    solidActiveBorder: '#db5759',
    solidDisabledBg: '#fccfcf',
    solidDisabledBorder: '#fccfcf',
  },
  warning: {
    solidColor: '#000',
    solidBg: '#ffc107',
    solidBorder: '#ffc107',
    solidHoverBg: '#ffca2c',
    solidHoverBorder: '#ffc720',
    solidActiveBg: '#ffcd39',
    solidActiveBorder: '#ffc720',
    solidDisabledBg: '#ffc107',
    solidDisabledBorder: '#ffc107',
  },
  info: {
    solidColor: '#000',
    solidBg: '#0dcaf0',
    solidBorder: '#0dcaf0',
    solidHoverBg: '#31d2f2',
    solidHoverBorder: '#25cff2',
    solidActiveBg: '#3dd5f3',
    solidActiveBorder: '#25cff2',
    solidDisabledBg: '#0dcaf0',
    solidDisabledBorder: '#0dcaf0',
  },
  black: {
    solidColor: '#fff',
    solidBg: '#333',
    solidBorder: '#333',
    solidHoverBg: '#333',
    solidHoverBorder: '#333',
    solidActiveBg: '#333',
    solidActiveBorder: '#333',
    solidDisabledBg: '#333',
    solidDisabledBorder: '#333',
  },
};
const materialPalette = {
  primary: {
    main: '#db5759',
    dark: '#7d3531',
    light: '#f7635c',
    contrastText: '#fff',
  },
  secondary: {
    main: '#6c757d',
    dark: '#51585e',
    light: '#5c636a',
    contrastText: '#fff',
  },
  success: {
    main: '#198754',
    dark: '#13653f',
    light: '#157347',
    contrastText: '#fff',
  },
  error: {
    main: '#dc3545',
    dark: '#a52834',
    light: '#b02a37',
    contrastText: '#fff',
  },
  warning: {
    main: '#ffc107',
    dark: '#ffc720',
    light: '#ffca2c',
    contrastText: '#000',
  },
  info: {
    main: '#0dcaf0',
    dark: '#25cff2',
    light: '#31d2f2',
    contrastText: '#000',
  },
  black: {
    main: '#333',
    dark: '#333',
    light: '#333',
    contrastText: '#fff',
  },
};

export const joyTheme = joyExtendTheme({
  colorSchemes: {
    light: {
      palette,
    },
    dark: {
      palette,
    },
  },
});

export const materialTheme = materialExtendTheme({
  colorSchemes: {
    light: {
      palette: materialPalette,
    },
    dark: {
      palette: materialPalette,
    },
  },
});
