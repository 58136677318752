import styled from 'styled-components';

export const LayoutContainer = styled.div`
  height: 100vh;
`;

export const BodyContainer = styled.div`
  display: flex;
  height: calc(
    100vh -
      ${(props) =>
        `${parseInt(props.theme.headerHeight.replace('px', ''), 10) + 10}px`}
  );
`;

export const BodyMainContent = styled.div`
  width: 100%;
  overflow-y: scroll;
  flex-grow: 1;
`;
export const StyledLogo = styled.img`
  inset: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
