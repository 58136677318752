import React, { CSSProperties } from 'react';
import { tvleb } from './assets';

const OfflinePage = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as CSSProperties['flexDirection'],
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#F4F4F4',
      color: '#333333',
      fontFamily: 'Courgette, sans-serif',
      fontSize: '24px',
    },
    message: {
      textAlign: 'center' as CSSProperties['textAlign'],
      color: '#4b3232',
    },
    advice: {
      marginTop: '20px',
      fontSize: '20px',
    },
    logo: {
      fontSize: '48px',
      color: '#c95757',
      fontFamily: 'Pacifico, cursive',
    },
    button: {
      marginTop: '30px',
      padding: '10px 20px',
      fontSize: '18px',
      backgroundColor: '#c95757',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontFamily: 'Dancing Script, cursive',
    },
  };

  return (
    <div style={styles.container}>
      <img style={styles.logo} src={tvleb} alt="Tvleb" />
      <h1 style={styles.message}>Oops! You are offline...</h1>
      <p style={styles.advice}>
        Please check your internet connection and try again.
      </p>
      <button
        type="button"
        style={styles.button}
        onClick={() => window.location.reload()}
      >
        Try Reconnecting
      </button>
    </div>
  );
};

export default OfflinePage;
