import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useSidebarTabs } from './config';
import { tvleb } from '../../../assets';

const Sidebar = () => {
  const location = useLocation();
  const { isSidebarCollapsed, userType, ...userInfo } = useSelector(
    (state: { user: any }) => state.user,
  );
  const { common } = useSidebarTabs(
    userType,
    userInfo.phone?.country?.countryCode,
  );

  return (
    <div
      style={{
        height: '100%',
        width: isSidebarCollapsed ? '45px' : '180px',
        maxWidth: isSidebarCollapsed ? '45px' : '180px',
        minWidth: isSidebarCollapsed ? '45px' : '180px',
        background: '#232524',

        boxShadow: '1px 2px 3px 2px #00000090',
        overflow: 'hidden',
        marginRight: '10px',
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '2px',
        position: 'relative',
      }}
    >
      {common.map((tab) => (
        <Link
          key={tab?.name}
          to={tab?.path}
          style={{
            color: 'white',
            background: location.pathname === tab.path ? '#db5759' : '',
            textDecoration: 'none',
            display: 'flex',
            padding: '5px',
            alignItems: 'center',
            margin: '5px',
            borderRadius: '5px',
            gap: '10px',
            fontSize: '0.9rem',
            width: 'calc(100% - 10px)',
            justifyContent: isSidebarCollapsed ? 'center' : 'flex-start',
          }}
          title={tab?.name}
        >
          <tab.icon style={{ width: '1.1rem', height: '1.1rem' }} />
          {!isSidebarCollapsed && tab?.name}
        </Link>
      ))}
      <img
        src={tvleb}
        alt="company logo"
        style={{
          width: '90%',
          height: 'auto',
          position: 'absolute',
          bottom: '10px',
        }}
      />
    </div>
  );
};
export default Sidebar;
