import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isProcessDialogOpen: false,
};

const processDialogSlice = createSlice({
  name: 'processDialog',
  initialState,
  reducers: {
    openProcessDialog(state) {
      return {
        ...state,
        isProcessDialogOpen: true,
      };
    },
    closeProcessDialog(state) {
      return {
        ...state,
        isProcessDialogOpen: false,
      };
    },
  },
});

export const { openProcessDialog, closeProcessDialog } =
  processDialogSlice.actions;
export default processDialogSlice.reducer;
