import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeProcessDialog } from './redux/slices/notificationPanel';
import ProcessNotificationButton from './components/process-notification-button';
import ProcessViewer from './components/process-viewer';

const NotificationProcessor = () => {
  const dispatch = useDispatch();
  const [isProcessDialogOpen, setIsProcessDialogOpen] =
    useState<boolean>(false);
  const processDialog = useSelector(
    (state: any) => state.processDialog.isProcessDialogOpen,
  );
  return (
    <>
      <ProcessNotificationButton
        setIsProcessDialogOpen={setIsProcessDialogOpen}
      />
      <ProcessViewer
        isOpen={isProcessDialogOpen || processDialog}
        onClose={() => {
          setIsProcessDialogOpen(false);
          dispatch(closeProcessDialog());
        }}
      />
    </>
  );
};

export default NotificationProcessor;
