import styled from 'styled-components';

export const Backdrop = styled.div`
  background: #00000090;
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dialog = styled.div`
  width: 500px;
  height: 400px;
  background: rgba(255, 255, 255, 0.1);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 10px;

  @media (max-width: 800px) {
    border-radius: 0px;
    width: 100%;
    height: calc(100% - 50px);
    border-radius: 0;
    margin-top: 50px;
  }
`;

export const DialogTitle = styled.h2`
  padding: 16px;
  font-size: 1.5rem;
  text-align: center;
  color: #ffffff;
  background-color: #ff555555;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-transform: uppercase;
  @media (max-width: 800px) {
    border-radius: 0px;
  }
`;

export const DialogContent = styled.div`
  padding: 16px;
  color: #ffffff;
  flex-grow: 1;
  max-height: 400px;
  overflow: auto;
  scrollbar-width: 3px; /* For Firefox */
  scrollbar-color: #fff #ffffff00;

  @media (max-width: 800px) {
    max-height: 100%;
  }

  /* Scrollbar Styles for Webkit Browsers */
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff; /* Track background */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff; /* Thumb color */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9b03b0; /* Thumb color when hovering */
    border-radius: 10px;
  }
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const DialogButton = styled.button`
  margin-left: 8px;
  padding: 8px 16px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #cccccc;
  }
`;

export const MegaPhoneImage = styled.img`
  position: absolute;
  inset: 0;
  opacity: 0.03;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  @media (min-width: 700px) {
    position: absolute;
    inset: 0;
    opacity: 0.03;
  }
`;
