// @ts-nocheck

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CustomerFieldsContainer } from '../../pages/dealers/dealers-list/add-shahid-to-dealer/styles';
import { deleteAllProcesses } from '../../redux/slices/backgroundProcess';
import { UlContainer, LiContainer } from './styles';
import AccordionDeleteButton from './accordion-delete-button';

const ProcessViewer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const processes = useSelector((state: any) => state.processes.processes);
  // const previousProcessesRef = useRef(processes);

  const [isDeleteText, setIsDeleteText] = useState<boolean>(false);

  const handleDeleteAll = () => {
    dispatch(deleteAllProcesses());
    setIsDeleteText(false);
  };

  // useEffect(() => {
  //   const handleProcessStatusChange = (
  //     processId: string,
  //     newStatus: string,
  //   ) => {
  //     switch (newStatus) {
  //       case 'pending':
  //         break;
  //       case 'in-progress':
  //         toast.info(`${processes[processId].title} is now ${newStatus}`);
  //         break;
  //       case 'completed':
  //         toast.success(`${processes[processId].title} is now ${newStatus}`);
  //         break;
  //       case 'failed':
  //         toast.error(`${processes[processId].title} ${newStatus}`);
  //         break;
  //       default:
  //         toast.info(`Process is running in the background`);
  //     }
  //   };

  //   Object.entries(processes).forEach(([processId, process]) => {
  //     const previousProcess = previousProcessesRef.current[processId];
  //     if (!previousProcess || previousProcess.status !== process.status) {
  //       handleProcessStatusChange(processId, process.status);
  //     }
  //   });

  //   previousProcessesRef.current = processes;
  // }, [processes]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();
        setIsDeleteText(false);
      }}
      anchor="right"
    >
      <Box sx={{ padding: '20px' }}>
        <CustomerFieldsContainer>
          <h5>List Of Recent Processes:</h5>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isDeleteText ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <p style={{ alignSelf: 'flex-start' }}>Are you sure?</p>
                <div style={{ alignSelf: 'flex-end' }}>
                  <Button
                    sx={{ color: 'grey' }}
                    onClick={() => setIsDeleteText(false)}
                  >
                    cancel
                  </Button>
                  <Button onClick={handleDeleteAll}>yes</Button>
                </div>
              </div>
            ) : (
              <Button
                disabled={Object.keys(processes).length === 0}
                onClick={() => setIsDeleteText(true)}
              >
                Clear All
              </Button>
            )}
          </div>
          {Object.keys(processes).length > 0 ? (
            <UlContainer>
              {Object.entries(processes)
                .reverse()
                .map(([processId, process]) => (
                  <LiContainer key={processId}>
                    {process.status === 'completed' && (
                      <Accordion sx={{ maxWidth: '400px' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <CheckCircleIcon
                              style={{
                                color: 'green',
                                marginRight: 8,
                              }}
                            />
                            <Typography variant="body1">
                              {process.title}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {process.content.split('\n').map((line, i) => (
                            <>
                              {line}
                              {i > 0 && <br />}
                            </>
                          ))}
                        </AccordionDetails>
                        <AccordionDeleteButton processId={processId} />
                      </Accordion>
                    )}
                    {process.status === 'failed' && (
                      <Accordion sx={{ maxWidth: '400px' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <CancelIcon
                              style={{
                                color: 'red',
                                verticalAlign: 'middle',
                                marginRight: 8,
                              }}
                            />
                            <Typography variant="body1">
                              {process.title}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {process.content.split('\n').map((line, i) => (
                            <>
                              {line}
                              {i > 0 && <br />}
                            </>
                          ))}
                        </AccordionDetails>
                        <AccordionDeleteButton processId={processId} />
                      </Accordion>
                    )}
                    {process.status === 'in-progress' && (
                      <Accordion sx={{ maxWidth: '400px' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <CircularProgress
                              size={16}
                              sx={{
                                verticalAlign: 'middle',
                                marginRight: 1.5,
                                '& .MuiCircularProgress-circle': {
                                  color: '#ffc107',
                                },
                              }}
                            />
                            <Typography variant="body1">
                              {process.title}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {process.content.split('\n').map((line, i) => (
                            <>
                              {line}
                              {i > 0 && <br />}
                            </>
                          ))}
                        </AccordionDetails>
                        <AccordionDeleteButton processId={processId} />
                      </Accordion>
                    )}
                  </LiContainer>
                ))}
            </UlContainer>
          ) : (
            <p>No Ongoing processes.</p>
          )}
        </CustomerFieldsContainer>
      </Box>
    </Drawer>
  );
};

export default ProcessViewer;
