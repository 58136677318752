import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import HubIcon from '@mui/icons-material/Hub';
import HistoryIcon from '@mui/icons-material/History';
import { WhatsApp } from '@mui/icons-material';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
// eslint-disable-next-line import/no-extraneous-dependencies
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
// eslint-disable-next-line import/no-extraneous-dependencies

import {
  // STOCK,
  CUSTOMERS as CUSTOMERS_TEXT,
  // SUBSCRIPTIONS as SUBSCRIPTIONS_TEXT,
  DEALERS as DEALERS_TEXT,
  TRANSACTIONS as TRANSACTIONS_TEXT,
  WHISH as WHISH_TEXT,
  EMPLOYEES as EMPLOYEES_TEXT,
  OPERATIONS as OPERATIONS_TEXT,
  WHATSAPP as WHATSAPP_TEXT,
  SHAHID_STOCK_TEXT,
  NETFLIX_STOCK_TEXT,
  SHAHID_SUBSCRIPTIONS_TEXT,
  SPOTIFY_TEXT,
  ANGHAMI_STOCK_TEXT,
  ACTION_HISTORY,
  ANNOUNCEMENTS,
  ANALYTICS_PAGE,
} from '../../../config/strings';
import {
  NETFLIX_STOCK,
  CUSTOMERS,
  DEALERS,
  EMPLOYEES,
  // SUBSCRIPTIONS,
  TRANSACTIONS,
  WHISH,
  OPERATIONS,
  WHATSAPP,
  SHAHID_STOCK,
  SHAHID_SUBSCRIPTIONS,
  SPOTIFY,
  ANGHAMI,
  ACTIONS_HISTORY,
  ANNOUNCEMENT,
  ANALYTICS,
} from '../../../router/routes';
import { USER_TYPES } from '../../../config/globals';
import NetflixIcon from './icons/netflixIcon';
import ShahidIcon from './icons/shahidIcon';
import AnghamiIcon from './icons/anghamiIcon';

const adminTabs = {
  common: [
    {
      name: NETFLIX_STOCK_TEXT,
      icon: NetflixIcon,
      path: NETFLIX_STOCK,
    },
    {
      name: SHAHID_STOCK_TEXT,
      icon: ShahidIcon,
      path: SHAHID_STOCK,
    },
    {
      name: ANGHAMI_STOCK_TEXT,
      icon: AnghamiIcon,
      path: ANGHAMI,
    },
    {
      name: SPOTIFY_TEXT,
      icon: DesktopWindowsOutlinedIcon,
      path: SPOTIFY,
    },
    // {
    //   name: SUBSCRIPTIONS_TEXT,
    //   icon: SubscriptionsIcon,
    //   path: SUBSCRIPTIONS,
    // },
    {
      name: SHAHID_SUBSCRIPTIONS_TEXT,
      icon: SubscriptionsIcon,
      path: SHAHID_SUBSCRIPTIONS,
    },
    {
      name: CUSTOMERS_TEXT,
      icon: PeopleAltOutlinedIcon,
      path: CUSTOMERS,
    },

    {
      name: EMPLOYEES_TEXT,
      icon: SupportAgentIcon,
      path: EMPLOYEES,
    },

    {
      name: DEALERS_TEXT,
      icon: StoreOutlinedIcon,
      path: DEALERS,
    },
    {
      name: WHISH_TEXT,
      icon: CurrencyExchangeIcon,
      path: WHISH,
    },
    {
      name: TRANSACTIONS_TEXT,
      icon: ReceiptLongOutlinedIcon,
      path: TRANSACTIONS,
    },
    {
      name: WHATSAPP_TEXT,
      icon: WhatsApp,
      path: WHATSAPP,
    },

    {
      name: OPERATIONS_TEXT,
      icon: HubIcon,
      path: OPERATIONS,
    },
    {
      name: ACTION_HISTORY,
      icon: HistoryIcon,
      path: ACTIONS_HISTORY,
    },
    {
      name: ANNOUNCEMENTS,
      icon: CampaignOutlinedIcon,
      path: ANNOUNCEMENT,
    },
    {
      name: ANALYTICS_PAGE,
      icon: InsightsOutlinedIcon,
      path: ANALYTICS,
    },
  ],
};

const employeeTabs = {
  common: [
    {
      name: NETFLIX_STOCK_TEXT,
      icon: NetflixIcon,
      path: NETFLIX_STOCK,
    },
    {
      name: SHAHID_STOCK_TEXT,
      icon: ShahidIcon,
      path: SHAHID_STOCK,
    },
    {
      name: SPOTIFY_TEXT,
      icon: AnghamiIcon,
      path: SPOTIFY,
    },
    {
      name: ANGHAMI_STOCK_TEXT,
      icon: AnghamiIcon,
      path: ANGHAMI,
    },
    // {
    //   name: SUBSCRIPTIONS_TEXT,
    //   icon: SubscriptionsIcon,
    //   path: SUBSCRIPTIONS,
    // },
    {
      name: SHAHID_SUBSCRIPTIONS_TEXT,
      icon: SubscriptionsIcon,
      path: SHAHID_SUBSCRIPTIONS,
    },
    {
      name: CUSTOMERS_TEXT,
      icon: PeopleAltOutlinedIcon,
      path: CUSTOMERS,
    },
    {
      name: DEALERS_TEXT,
      icon: StoreOutlinedIcon,
      path: DEALERS,
    },
    {
      name: WHISH_TEXT,
      icon: CurrencyExchangeIcon,
      path: WHISH,
    },
    {
      name: TRANSACTIONS_TEXT,
      icon: ReceiptLongOutlinedIcon,
      path: TRANSACTIONS,
    },
    {
      name: WHATSAPP_TEXT,
      icon: WhatsApp,
      path: WHATSAPP,
    },
    {
      name: OPERATIONS_TEXT,
      icon: HubIcon,
      path: OPERATIONS,
    },
    {
      name: ACTION_HISTORY,
      icon: HistoryIcon,
      path: ACTIONS_HISTORY,
    },
    {
      name: ANNOUNCEMENTS,
      icon: CampaignOutlinedIcon,
      path: ANNOUNCEMENT,
    },
  ],
};

const dealerTabs = {
  common: [
    {
      name: CUSTOMERS_TEXT,
      icon: PeopleAltOutlinedIcon,
      path: CUSTOMERS,
    },
    {
      name: SHAHID_SUBSCRIPTIONS_TEXT,
      icon: SubscriptionsIcon,
      path: SHAHID_SUBSCRIPTIONS,
    },
    {
      name: NETFLIX_STOCK_TEXT,
      icon: DesktopWindowsOutlinedIcon,
      path: NETFLIX_STOCK,
    },

    // {
    //   name: SUBSCRIPTIONS_TEXT,
    //   icon: SubscriptionsIcon,
    //   path: SUBSCRIPTIONS,
    // },

    // {
    //   name: WHATSAPP_TEXT,
    //   icon: WhatsApp,
    //   path: WHATSAPP,
    // },
    {
      name: TRANSACTIONS_TEXT,
      icon: ReceiptLongOutlinedIcon,
      path: TRANSACTIONS,
    },
  ],
};
const notLebanonDealerTabs = {
  common: [
    {
      name: SHAHID_SUBSCRIPTIONS_TEXT,
      icon: SubscriptionsIcon,
      path: SHAHID_SUBSCRIPTIONS,
    },
    // {
    //   name: SUBSCRIPTIONS_TEXT,
    //   icon: SubscriptionsIcon,
    //   path: SUBSCRIPTIONS,
    // },

    {
      name: CUSTOMERS_TEXT,
      icon: PeopleAltOutlinedIcon,
      path: CUSTOMERS,
    },
    // {
    //   name: WHATSAPP_TEXT,
    //   icon: WhatsApp,
    //   path: WHATSAPP,
    // },
    {
      name: TRANSACTIONS_TEXT,
      icon: ReceiptLongOutlinedIcon,
      path: TRANSACTIONS,
    },
  ],
};

export const useSidebarTabs = (userType, countryCode): { common: any[] } => {
  switch (userType) {
    case USER_TYPES.ADMIN:
      return adminTabs;
    case USER_TYPES.EMPLOYEE:
      return employeeTabs;
    case USER_TYPES.DEALER:
      return dealerTabs;
    default:
      return { common: [] };
  }
};
