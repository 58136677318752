// @ts-nocheck

import axios from 'axios';
import URLs from '../../config/api-endpoints';
import { getAuthToken } from '../../utils/session';

const getPendingAccountsCount = (): any => {
  const jwt = getAuthToken();
  const url = URLs.PENDING.GET_PENDING_ACCOUNTS_COUNTS;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export default getPendingAccountsCount;
