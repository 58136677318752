const {
  REACT_APP_BACKEND_URL: backendBaseUrl = '',
  REACT_APP_BACKEND_URL_V2: backendBaseUrlV2 = backendBaseUrl,
} = process.env;

const URLs = {
  UNDER_MAINTENANCE: {
    GET_IF_UNDER_MAINTENANCE: `${backendBaseUrl}/underMaintenance`,
  },
  WHATSAPP: {
    SIGN_IN: `${backendBaseUrl}/whatsapp/login`,
    REGISTER: `${backendBaseUrl}/whatsapp/register`,
    CLIENTS: `${backendBaseUrl}/whatsapp/newClient`,
    NEW_SESSION: `${backendBaseUrl}/whatsapp/newSession`,
    PAIRING_CODE: `${backendBaseUrl}/whatsapp/pairingCode`,
    SEND_MESSAGE: `${backendBaseUrl}/whatsapp/sendMessage`,
    SEND_MESSAGE_TO_DEALERS: `${backendBaseUrl}/whatsapp/sendMessageToDealers`,
  },
  WHATSAPP_MESSAGES: {
    GET_WHATSAPP_MESSAGES: `${backendBaseUrl}/whatsappMessages`,
    GET_WHATSAPP_MESSAGE_TYPES: `${backendBaseUrl}/whatsappMessages/types`,
    CREATE_WHATSAPP_MESSAGE_TYPES: `${backendBaseUrl}/whatsappMessages/types/create`,
  },
  PENDING: {
    GET_ALL_PENDING_ACCOUNTS: `${backendBaseUrl}/pending`,
    GET_PENDING_ACCOUNTS_COUNTS: `${backendBaseUrl}/pending/count`,
    REJECT_PENDING_REQUEST: (id) => `${backendBaseUrl}/pending/${id}/reject`,
  },
  USER: {
    CHANGE_PASSWORD: `${backendBaseUrl}/user/changePassword`,
    UPDATE_BAN_STATUS: (userId) =>
      `${backendBaseUrl}/user/updateBannedStatus/${userId}`,
  },
  ANGHAMI: {
    UPDATE_ANGHAMI_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/anghami/${accountId}`,
    UPDATE_ANGHAMI_LINK: (linkId) => `${backendBaseUrl}/anghami/link/${linkId}`,
    CREATE_ANGHAMI_ACCOUNT: `${backendBaseUrl}/anghami`,
    GET_ANGHAMI_ACCOUNT: `${backendBaseUrl}/anghami`,
    CREATE_ANGHAMI_ACCOUNT_TYPE: `${backendBaseUrl}/anghami/type`,
    EDIT_ANGHAMI_TYPE: (id) => `${backendBaseUrl}/anghami/type/${id}`,
    GET_ANGHAMI_ACCOUNT_BY_ID: (id) =>
      `${backendBaseUrl}/anghami/account/${id}`,
    GET_ANGHAMI_TYPES: `${backendBaseUrl}/anghami/types`,
    BUY_ANGHAMI_ACCOUNT: `${backendBaseUrl}/anghami/buy`,
    GET_ANGHAMI_SUBSCRIPTIONS: `${backendBaseUrl}/anghami/subscriptions`,
    GET_ANGHAMI_TOTAL_COUNT: `${backendBaseUrl}/anghami/totalCount`,
    GET_ANGHAMI_SUBSCRIPTION_DATA: `${backendBaseUrl}/anghami/getAnghamiSubscriptionData`,
  },
  ORDER: {
    GET_ALL: `${backendBaseUrl}/orders`,
    DELETE_ORDER: (orderId) => `${backendBaseUrl}/orders/${orderId}`,
    CREATE_ORDER: `${backendBaseUrl}/orders`,
  },
  TRANSACTIONS: {
    GET_TRANSACTIONS_NOTIFICATIONS: `${backendBaseUrl}/transactions/notifications`,
    GET_TRANSACTIONS: `${backendBaseUrl}/transactions`,
    CHANGE_CURRENCY: `${backendBaseUrl}/transactions/change-currency`,
    FIX_CURRENCY: `${backendBaseUrl}/transactions/fix-currency`,
  },
  DEALER_TRANSACTIONS: {
    GET_DEALER_TRANSACTIONS: (dealerId) =>
      `${backendBaseUrl}/dealers/${dealerId}/transactions`,
    GET_ALL_DEALERS_TRANSACTIONS: `${backendBaseUrl}/dealers/transactions`,
  },
  ACCOUNT: {
    SIGN_IN: `${backendBaseUrl}/account/signIn`,
  },
  DEALERS: {
    ADD_DEALER: `${backendBaseUrl}/dealers`,
    GET_DEALERS: `${backendBaseUrl}/dealers`,
    ADD_BALANCE: (dealerId) => `${backendBaseUrl}/dealers/${dealerId}/balance`,
    UPDATE_DISCOUNT: (dealerId) =>
      `${backendBaseUrl}/dealers/${dealerId}/discount`,
    UPDATE_ACCOUNTS_PRICE: (dealerId) =>
      `${backendBaseUrl}/dealers/accounts-price/${dealerId}`,
    UPDATE_ACCOUNT_OWNERSHIP_RENEWAL: (netflixAccountId) =>
      `${backendBaseUrl}/dealers/autoRenewal/${netflixAccountId}`,
    GET_DEALER_BALANCE: (dealerId) =>
      `${backendBaseUrl}/dealers/${dealerId}/balance`,
    UPDATE_DEALER_INFO: (dealerId) =>
      `${backendBaseUrl}/user/updateDealerInfo/${dealerId}`,
  },
  CANCELED_SUBSCRIPTIONS: {
    GET_ALL_CANCELED_SUBSCRIPTIONS: `${backendBaseUrl}/canceledSubscriptions`,
    GET_CANCELED_SUBSCRIPTIONS_FOR_SPECIFIC_CUSTOMER: (customerId) =>
      `${backendBaseUrl}/canceledSubscriptions/${customerId}`,
    GET_CANCELED_SUBSCRIPTIONS_FOR_SPECIFIC_CUSTOMER_BY_PHONE: (phoneNumber) =>
      `${backendBaseUrl}/canceledSubscriptions/byPhone/${phoneNumber}`,
    UPDATE_CANCELED_SUBSCRIPTION_REASON: (id) =>
      `${backendBaseUrl}/canceledSubscriptions/updateReason/${id}`,
    UPDATE_CANCELED_SUBSCRIPTION_CONTACTED: (id) =>
      `${backendBaseUrl}/canceledSubscriptions/updateContacted/${id}`,
    UPDATE_CANCELED_SUBSCRIPTION_RESOLVED: (id) =>
      `${backendBaseUrl}/canceledSubscriptions/updateResolved/${id}`,
  },
  CANCELLATION_REQUESTS: {
    GET_ALL: `${backendBaseUrl}/cancellationRequests`,
  },
  NETFLIX_ACCOUNTS: {
    GET_ALL_EXPIRED_NETFLIX_ACCOUNT_FOR_SIGN_OUT: `${backendBaseUrl}/netflix-accounts/allExpiredAccountsForSignOut`,
    GET_ALL_EXPIRED_NETFLIX_ACCOUNT: `${backendBaseUrl}/netflix-accounts/allExpiredAccounts`,
    GET_EMPTY_DEALER_ACCOUNTS: `${backendBaseUrl}/netflix-accounts/emptyDealerAccounts`,
    GET_EXPIRED_NETFLIX_ACCOUNT: `${backendBaseUrl}/netflix-accounts/expiredAccounts`,
    GET_EXPIRED_NETFLIX_ACCOUNT_TO_CANCEL: `${backendBaseUrl}/netflix-accounts/accountsForCancelation`,
    ADD_NETFLIX_ACCOUNT: `${backendBaseUrl}/netflix-accounts`,
    ADD_NETFLIX_ACCOUNTS_TO_DEALER: `${backendBaseUrl}/netflix-accounts/addMultipleAccounts`,
    ADD_NETFLIX_ACCOUNT_BULK: `${backendBaseUrl}/netflix-accounts/bulkAdd`,
    GET_ALL_NETFLIX_ACCOUNTS: `${backendBaseUrl}/netflix-accounts`,
    GET_NETFLIX_ACCOUNTS_STOCK: `${backendBaseUrl}/netflix-accounts/netflixStock`,
    GEt_NETFLIX_ACCOUNTS_WITH_ISSUES: `${backendBaseUrl}/netflix-accounts/issues`,
    GET_NETFLIX_RESET_LINK: `${backendBaseUrl}/netflix-accounts/resetLink`,
    GET_ALL_NETFLIX_ACCOUNTS_WITH_DEALERS: `${backendBaseUrl}/netflix-accounts/sold`,
    BUY_NETFLIX_ACCOUNT: `${backendBaseUrl}/netflix-accounts/buy`,
    FIX_PASSWORD: `${backendBaseUrl}/netflix-accounts/fix-password`,
    CHANGE_REGION: `${backendBaseUrl}/netflix-accounts/changeRegion`,
    CHECK_IF_EMAILS_EXIST: `${backendBaseUrl}/netflix-accounts/checkNetflixAccountWithOwnerExists`,
    EDIT_EMAIL: `${backendBaseUrl}/netflix-accounts/editEmail`,
    CHANGE_EMAIL: `${backendBaseUrl}/netflix-accounts/changeEmail`,
    RESET_LINKS: `${backendBaseUrl}/netflix-accounts/resetLinks`,
    CHANGE_PLAN: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/changePlan/${accountId}`,
    REPLACE_ACCOUNT_FROM_CANCEL: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/replaceFromCancel/${accountId}`,
    RETRY_CHANGE_EMAIL: `${backendBaseUrl}/netflix-accounts/retryChangeEmail`,
    CHANGE_PIN_CODE: `${backendBaseUrl}/netflix-accounts/updatePinCode`,
    CHANGE_PROFILE_NAME: `${backendBaseUrl}/netflix-accounts/changeProfileName`,
    SIGN_OUT_EXPIRED_NETFLIX_ACCOUNT_WITH_EMAIL: `${backendBaseUrl}/netflix-accounts/signOutNetflixAccountWithEmail`,
    CHANGE_NETFLIX_PASSWORD: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/changeNetflixPassword`,
    DELETE_NETFLIX_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/deleteNetflixAccount`,
    SIGN_OUT_EXPIRED_NETFLIX_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/signOutNetflixAccount`,
    FIX_NETFLIX_ACCOUNT_PROFILES: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/fixProfiles`,
    SIGN_OUT_EXPIRED_NETFLIX_ACCOUNT_WITH_RESET: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/signOutNetflixAccountWithResetLink`,
    RENEW_EXPIRED_NETFLIX_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/renewExpiredAccount`,
    SEND_CREDENTIALS_TO_CUSTOMER: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflix-accounts/${customerSubscriptionId}/sendCredentials`,
    ADD_DAYS_TO_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/addDaysToAccount`,
    ADD_FREE_DAYS_TO_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/addFreeDaysToAccount`,
    CANCEL_EXPIRED_NETFLIX_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/cancelExpiredAccount`,
    REPLACE_STANDARD_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflix-accounts/${accountId}/replaceStandardAccount/${accountId}`,
    GET_ALL_NETFLIX_ACCOUNTS_COUNT: `${backendBaseUrl}/netflix-accounts/getAllNetflixAccountsCount`,
    GET_ALL_PROBLEM_ACCOUNTS: `${backendBaseUrl}/netflix-accounts/problemAccounts`,
    REPORT_PAYMENT_ISSUE: `${backendBaseUrl}/netflix-accounts/reportNetflixAccountPaymentIssue`,
    FIX_PAYMENT_ISSUE: `${backendBaseUrl}/netflix-accounts/fixNetflixAccountPaymentIssue`,
    DELETE_PAYMENT_ISSUE: `${backendBaseUrl}/netflix-accounts/deleteNetflixAccountPaymentIssue`,
    GET_ALL_NETFLIX_ACCOUNT_WITH_PAYMENT_ISSUE: `${backendBaseUrl}/netflix-accounts/getNetflixAccountsWithPaymentIssue`,
  },
  CUSTOMERS: {
    ADD_CUSTOMER: `${backendBaseUrl}/customers`,
    GET_CUSTOMERS: `${backendBaseUrl}/customers`,
    GET_ALL_CUSTOMERS: `${backendBaseUrl}/customers/allCustomers`,
    CHECK_IF_PHONE_BELONGS_TO_DEALER: `${backendBaseUrl}/customers/checkIfDealerPhone`,
    GET_CUSTOMER_BY_PHONE: `${backendBaseUrl}/customers/customerByPhone`,
    GET_CUSTOMER: (customerId) => `${backendBaseUrl}/customers/${customerId}`,
    DELETE_CUSTOMER: (customerId) =>
      `${backendBaseUrl}/customers/${customerId}/deleteCustomer`,
    UPDATE_STATUS: (customerId) =>
      `${backendBaseUrl}/customers/${customerId}/status`,
    TRANSFORM_CUSTOMER_TO_DEALER: (customerId) =>
      `${backendBaseUrl}/customers/transformCustomerToDealer/${customerId}`,
  },
  AUDIO: {
    ADD_AUDIO_FILE: `${backendBaseUrl}/audio/uploadAudio`,
    ADD_IMAGE_FILE: `${backendBaseUrl}/audio/uploadImage`,
    AUDIO_FILE_URL: (fileName) =>
      `${backendBaseUrl}/audio/uploadedAudio/${fileName}`,
  },
  SETTINGS: {
    GET_SETTINGS: `${backendBaseUrl}/settings`,
    GET_BANNER: `${backendBaseUrl}/settings/banner`,
  },
  RESTART_WHATSAPP: {
    RESTART_WHATSAPP: `https://tvleb.com/restart_whatsapp`,
  },
  SENT_MESSAGES: {
    GET_SENT_MESSAGES: `${backendBaseUrl}/sentMessages`,
    SEND_MESSAGE_AGAIN: (messageId) =>
      `${backendBaseUrl}/sentMessages/sendAgain/${messageId}`,
    FIX_MESSAGE: (messageId) =>
      `${backendBaseUrl}/sentMessages/fixMessage/${messageId}`,
  },
  NETFLIX_SUBSCRIPTION: {
    GET_ALL_NETFLIX_SUBSCRIPTIONS: `${backendBaseUrl}/netflixSubscription`,
    GET_ALL_NETFLIX_SUBSCRIPTIONS_ACCOUNTS: `${backendBaseUrl}/netflixSubscription/subscriptionsAccounts`,
    SIGN_OUT_FULL_ACCOUNTS_WITH_SPECIFIED_DATE: `${backendBaseUrl}/netflixSubscription/signOutFullAccountsWithSpecifiedDate`,
    ADD_ONE_MONTH_TO_CUSTOMER_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/addOneMonthToSubscription/${customerSubscriptionId}`,
    RENEW_EXPIRED_NETFLIX_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/renewSubscription/${customerSubscriptionId}`,
    UPDATE_SUBSCRIPTION_END_DATE: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/setSubscriptionDate/${customerSubscriptionId}`,
    REMOVE_PROFILE_PIN_CODE: (profileId) =>
      `${backendBaseUrl}/netflixSubscription/removeProfilePinCode/${profileId}`,
    PAUSE_NETFLIX_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/pauseSubscription/${customerSubscriptionId}`,
    MARK_AS_PAID: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/markAccountAsPaid/${customerSubscriptionId}`,
    CANCEL_CUSTOMER_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/cancelSubscriptions/${customerSubscriptionId}`,
    ADD_LABEL_TO_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/updateLabels/${customerSubscriptionId}`,
    CANCEL_FULL_CUSTOMER_SUBSCRIPTION: (cancelRequestId) =>
      `${backendBaseUrl}/netflixSubscription/cancelFullSubscriptions/${cancelRequestId}`,
    UPDATE_CUSTOMER_SUBSCRIPTION_TYPE: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/updateSubscriptionTypeOfCustomer/${customerSubscriptionId}`,
    UPDATE_CUSTOMER_SUBSCRIPTION_AUTO_RENEWAL: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/autoRenewal/${customerSubscriptionId}`,
    GET_NETFLIX_SUBSCRIPTION_CUSTOMERS: (subscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/subscriptionCustomer/${subscriptionId}`,
    GET_NETFLIX_ACCOUNT_CUSTOMERS: (accountId) =>
      `${backendBaseUrl}/netflixSubscription/subscriptionCustomerByAccount/${accountId}`,
    ADD_CUSTOMER_TO_ACCOUNT: (accountId) =>
      `${backendBaseUrl}/netflixSubscription/addToAccount/${accountId}`,
    EDIT_NOTE: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/editNetflixNote/${customerSubscriptionId}`,
    ADD_CUSTOMER_TO_NETFLIX_ACCOUNT_SUBSCRIPTION: `${backendBaseUrl}/netflixSubscription/`,
    COMBINE_SUBSCRIPTIONS: `${backendBaseUrl}/netflixSubscription/combineTwoSubscriptions`,
    GET_NETFLIX_SUBSCRIPTIONS_TO_COMBINE: (subscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/getSubscriptionsToCombine/${subscriptionId}`,
    REPLACE_NETFLIX_SUBSCRIPTION_CUSTOMER: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/replaceNetflixSubscriptionCustomer/${customerSubscriptionId}`,
    CHANGE_NETFLIX_SUBSCRIPTION_TYPE: (subscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/changeNetflixSubscriptionPlan/${subscriptionId}`,
    REFUND_NETFLIX_DEALER_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/refundDealerSubscription/${customerSubscriptionId}`,
    ADD_DAYS_TO_NETFLIX_ACCOUNT: (subscriptionId) =>
      `${backendBaseUrl}/netflixSubscription/addDaysToNetflixSubscription/${subscriptionId}`,
    GET_NETFLIX_TOTAL_COUNT: `${backendBaseUrl}/netflixSubscription/getTotalCount`,
    GET_NETFLIX_SUBSCRIPTIONS_DATA: `${backendBaseUrl}/netflixSubscription/getNetflixSubscriptionData`,
    GET_NETFLIX_SUBSCRIPTIONS_DAILY: `${backendBaseUrl}/netflixSubscription/getAllNetflixSubscriptionsCountDaily`,
  },
  SHAHID: {
    BUY_SHAHID_ACCOUNT: `${backendBaseUrl}/shahid/buy`,
    CREATE_SHAHID_ACCOUNT: `${backendBaseUrl}/shahid`,
    CREATE_SHAHID_ACCOUNTS: `${backendBaseUrl}/shahid/bulkAdd`,
    GET_SHAHID_ACCOUNTS: `${backendBaseUrl}/shahid`,
    GET_SHAHID_REQUESTS: `${backendBaseUrl}/shahid/requests`,
    GET_SHAHID_STOCK: `${backendBaseUrl}/shahid/stock`,
    SEND_SUBSCRIPTION_TO_CUSTOMER: `${backendBaseUrl}/shahid/sendCredentialsToClient`,
    GET_RESET_LINK: `${backendBaseUrl}/shahid/resetLink`,

    GET_ALL_SHAHID_SUBSCRIPTIONS: `${backendBaseUrl}/shahid/subscriptions`,
    GET_ALL_SHAHID_SUBSCRIPTIONS_HISTORY: `${backendBaseUrl}/shahid/subscriptionsHistory`,
    GET_DEALER_SHAHID_REQUESTS: `${backendBaseUrl}/shahid/dealerShahidRequests`,
    GET_ALL_SHAHID_ACCOUNT_TYPES: `${backendBaseUrl}/shahid/shahidAccountTypes`,
    ADD_SHAHID_TO_DEALER: `${backendBaseUrl}/shahid/addToDealer`,
    CREATE_SHAHID_ACCOUNT_TYPE: `${backendBaseUrl}/shahid/shahidAccountType`,
    EDIT_NOTE: (customerSubscriptionId) =>
      `${backendBaseUrl}/shahid/editNote/${customerSubscriptionId}`,
    GET_SHAHID_ACCOUNT_WITH_DEALER_AND_CUSTOMER: (accountId) =>
      `${backendBaseUrl}/shahid/${accountId}/shahidAccount`,
    DELETE_SHAHID_ACCOUNT_TYPE: (id) =>
      `${backendBaseUrl}/shahid/${id}/shahidAccountType`,
    DELETE_SHAHID_ACCOUNT: (id) => `${backendBaseUrl}/shahid/${id}`,
    REQUEST_SWAP_SHAHID_ACCOUNT: (id) => `${backendBaseUrl}/shahid/swap/${id}`,
    DELETE_SHAHID_REQUEST: (id) =>
      `${backendBaseUrl}/shahid/shahidRequest/${id}`,
    REQUEST_SHAHID_ACCOUNT_RENEWAL: (id) =>
      `${backendBaseUrl}/shahid/renewal/${id}`,
    RENEW_SHAHID_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/shahid/renew/${customerSubscriptionId}`,
    CANCEL_SHAHID_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/shahid/cancel/${customerSubscriptionId}`,
    CHECK_ACTIVE_INACTIVE_SUBS: `${backendBaseUrl}/shahid/checkActiveInactiveSubscriptions`,
    CONVERT_ACCOUNTS_TO_HISTORY: `${backendBaseUrl}/shahid/updateShahidSubscriptionToHistory`,
    REQUEST_CHANGE_PASSWORD: (id) => `${backendBaseUrl}/shahid/password/${id}`,
    REQUEST_FIX_PASSWORD: (id) => `${backendBaseUrl}/shahid/fixPassword/${id}`,
    UPDATE_SHAHID_REQUEST: (id) =>
      `${backendBaseUrl}/shahid/${id}/updateRequest`,
    UPDATE_SHAHID_ACCOUNT: (id) => `${backendBaseUrl}/shahid/${id}`,
    UPDATE_SHAHID_ACCOUNT_TYPE: (id) =>
      `${backendBaseUrl}/shahid/${id}/shahidAccountType`,
    REPLACE_SHAHID_SUBSCRIPTION_CUSTOMER: (customerSubscriptionId) =>
      `${backendBaseUrl}/shahid/replaceShahidSubscriptionCustomer/${customerSubscriptionId}`,
    REFUND_SHAHID_SUBSCRIPTION: (customerSubscriptionId) =>
      `${backendBaseUrl}/shahid/refundShahidSubscription/${customerSubscriptionId}`,
    GET_SHAHID_TOTAL_COUNT: `${backendBaseUrl}/shahid/getTotalCount`,
    GET_SHAHID_SUBSCRIPTIONS_DATA: `${backendBaseUrl}/shahid/getShahidSubscriptionsData`,
  },
  SPOTIFY: {
    CREATE_SPOTIFY_ACCOUNT: `${backendBaseUrl}/spotify`,
    UPDATE_SPOTIFY_REQUEST: (id) => `${backendBaseUrl}/spotify/${id}`,
    UPDATE_SPOTIFY_REQUEST_CREDENTIALS: (id) =>
      `${backendBaseUrl}/spotify/${id}/credentials`,
    UPDATE_SPOTIFY_REQUEST_WRONG_CREDENTIALS: (id) =>
      `${backendBaseUrl}/spotify/${id}/wrongCredentials`,
    GET_SPOTIFY_ACCOUNTS_SUBSCRIPTIONS: `${backendBaseUrl}/spotify/subscriptions`,
    GET_SPOTIFY_ACCOUNTS: `${backendBaseUrl}/spotify`,
  },
  SUBSCRIPTIONS: {
    ADD_SUBSCRIPTION: `${backendBaseUrl}/subscriptions`,
    GET_CUSTOMER_SUBSCRIPTIONS: (customerId) =>
      `${backendBaseUrl}/subscriptions/customers/${customerId}`,
    GET_SUBSCRIPTIONS: `${backendBaseUrl}/subscriptions`,
    RENEW_SUBSCRIPTION: (subscriptionId) =>
      `${backendBaseUrl}/subscriptions/${subscriptionId}/renew`,
    EXTEND_SUBSCRIPTION: (subscriptionId) =>
      `${backendBaseUrl}/subscriptions/${subscriptionId}/extend`,
    UPDATE_PAYMENT_STATUS: (subscriptionId) =>
      `${backendBaseUrl}/subscriptions/${subscriptionId}/paymentStatus`,
    CANCEL_SUBSCRIPTION: (subscriptionId) =>
      `${backendBaseUrl}/subscriptions/${subscriptionId}/cancel`,
  },
  EMPLOYEES: {
    ADD_EMPLOYEE: `${backendBaseUrl}/employees`,
    GET_EMPLOYEES: `${backendBaseUrl}/employees`,
    DELETE_EMPLOYEE: (employeeId) =>
      `${backendBaseUrl}/employees/${employeeId}`,
  },
  CANCELED_ACCOUNTS: {
    GET_ALL_CANCELED_ACCOUNTS: `${backendBaseUrl}/canceled-accounts`,
    GET_ALL_CANCELED_ACCOUNTS_DEALERS: `${backendBaseUrl}/canceled-accounts/dealers`,
  },
  NETFLIX_PRICING: {
    GET_ALL_NETFLIX_PRICING: `${backendBaseUrl}/netflixPricing`,
    ADD_NETFLIX_PRICING: `${backendBaseUrl}/netflixPricing`,
    UPDATE_NETFLIX_PRICING: (pricingId) =>
      `${backendBaseUrl}/netflixPricing/${pricingId}`,
    GET_NETFLIX_SUBSCRIPTION_PRICING: (subscriptionTypeId) =>
      `${backendBaseUrl}/netflixPricing/getSubscriptionPrice/${subscriptionTypeId}`,
  },
  NETFLIX_REQUESTS: {
    GET_ALL_NETFLIX_REQUESTS: `${backendBaseUrl}/netflixRequests`,
  },
  ACTIONS_HISTORY: {
    GET_ALL_ACTIONS_HISTORY: `${backendBaseUrl}/actionsHistory/getall`,
  },
  ANNOUNCEMENTS: {
    CREATE_ANNOUNCEMENTS: `${backendBaseUrl}/announcements/`,
    GET_ALL_ANNOUNCEMENTS: `${backendBaseUrl}/announcements/all`,
    GET_ANNOUNCEMENT_BY_ID: (announcementId) =>
      `${backendBaseUrl}/announcements/${announcementId}`,
    UPDATE_ANNOUNCEMENT_BY_ID: (announcementId) =>
      `${backendBaseUrl}/announcements/${announcementId}`,
    DELETE_ANNOUNCEMENT_BY_ID: (announcementId) =>
      `${backendBaseUrl}/announcements/${announcementId}`,
  },
  PURCHASE_HISTORY: {
    GET_ALL_PURCHASE_HISTORY: `${backendBaseUrl}/purchaseHistory/getall`,
    GET_PURCHASE_HISTORY_BY_DATE: `${backendBaseUrl}/purchaseHistory/searchByDate`,
  },
  OSTORIES_ME: {
    GET_COUNT: `${backendBaseUrl}/ostoriesMe`,
  },
  ERROR_HISTORY: {
    GET_ALL_ERROR_HISTORY: `${backendBaseUrl}/errorHistory/getall`,
  },
};

export default URLs;
