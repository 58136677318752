/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processes: {},
};

const processSlice = createSlice({
  name: 'processes',
  initialState,
  reducers: {
    startProcess(state, action) {
      const { processId, title, content } = action.payload;
      state.processes[processId] = {
        status: 'pending',
        data: null, // data related to the process if exists
        title,
        content,
        error: null,
        timeStamp: Date.now(),
      };
      // state.processes = {};
    },
    updateProcess(state, action) {
      const { processId, data, title, content } = action.payload;
      state.processes[processId].status = 'in-progress';
      state.processes[processId].data = data;
      state.processes[processId].title = title;
      state.processes[processId].content = content;
    },
    finishProcess(state, action) {
      const { processId, data, title, content } = action.payload;
      state.processes[processId].status = 'completed';
      state.processes[processId].data = data;
      state.processes[processId].title = title;
      state.processes[processId].content = content;
    },
    handleProcessError(state, action) {
      const { processId, error, title, content } = action.payload;
      state.processes[processId].status = 'failed';
      state.processes[processId].title = title;
      state.processes[processId].content = content;
      state.processes[processId].error = error;
    },
    deleteProcess(state, action) {
      const { processId } = action.payload;
      delete state.processes[processId];
    },
    deleteAllProcesses(state) {
      state.processes = {};
    },
    deleteOldProcesses(state) {
      const now = Date.now();
      const twoDays = 1 * 24 * 60 * 60 * 1000;
      Object.keys(state.processes).forEach((processId) => {
        if (now - state.processes[processId].timeStamp > twoDays) {
          delete state.processes[processId];
        }
      });
    },
  },
});

export const {
  startProcess,
  updateProcess,
  finishProcess,
  handleProcessError,
  deleteProcess,
  deleteAllProcesses,
  deleteOldProcesses,
} = processSlice.actions;

export default processSlice.reducer;
