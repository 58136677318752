export const COMPANY_NAME = 'TvLeb';

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  DEALER: 'DEALER',
  EMPLOYEE: 'EMPLOYEE',
  DEFAULT: 'DEFAULT',
};

export const WHATSAPP_MESSAGES_TEMPLATE_TYPES = {
  REMINDER: {
    id: 1,
    description: 'reminder',
    code: 'REMINDER',
  },
  SUBSCRIPTION: {
    id: 2,
    description: 'subscription',
    code: 'SUBSCRIPTION',
  },
  RESET_PASSWORD: {
    id: 3,
    description: 'reset password',
    code: 'RESET_PASSWORD',
  },
  ACCOUNT_EXPIRED: {
    id: 4,
    description: 'account expired',
    code: 'ACCOUNT_EXPIRED',
  },
  ACCOUNT_CANCELLED: {
    id: 5,
    description: 'account cancelled',
    code: 'ACCOUNT_CANCELLED',
  },
  ACCOUNT_RENEWED: {
    id: 6,
    description: 'account renewed',
    code: 'ACCOUNT_RENEWED',
  },
  ACCOUNT_RENEWAL_FAILED: {
    id: 7,
    description: 'account renewal failed',
    code: 'ACCOUNT_RENEWAL_FAILED',
  },
  ACCOUNT_INFO: {
    id: 8,
    description: 'account info',
    code: 'ACCOUNT_INFO',
  },
};

export const PLATFORM_ROLES = {
  ADMIN: {
    id: 1,
    description: 'admin',
  },
  EMPLOYEE: {
    id: 2,
    description: 'employee',
  },
  DEALER: {
    id: 3,
    description: 'dealer',
  },
};
export const SPOTIFY_SUBSCRIPTION_TYPES = {
  THREE_MONTHS: {
    id: 1,
    description: '3 Months',
    code: 'THREE_MONTHS',
    months: 3,
    price: 4,
  },
  SIX_MONTHS: {
    id: 2,
    description: '6 Months',
    code: 'SIX_MONTHS',
    months: 6,
    price: 8,
  },
  ONE_YEAR: {
    id: 3,
    description: '1 Year',
    code: 'ONE_YEAR',
    months: 12,
    price: 12,
  },
};

export const NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY = {
  1: {
    id: 1,
    description: 'Lebanon',
    code: 'LEBANON',
  },
  2: {
    id: 2,
    description: 'France',
    code: 'FRANCE',
  },
  3: {
    id: 3,
    description: 'Italy',
    code: 'ITALY',
  },
  4: {
    id: 4,
    description: 'Turkey',
    code: 'TURKEY',
  },
  5: {
    id: 5,
    description: 'Egypt',
    code: 'EGYPT',
  },
  6: {
    id: 6,
    description: 'United Kingdom',
    code: 'UNITED_KINGDOM',
  },
};

export const NETFLIX_ACCOUNT_TYPES = {
  PREMIUM: {
    id: 1,
    description: 'premium',
    code: 'PREMIUM',
  },
  STANDARD: {
    id: 2,
    description: 'standard',
    code: 'STANDARD',
  },
  BASIC: {
    id: 3,
    description: 'basic',
    code: 'BASIC',
  },
  PREMIUM_FOR_SHARING: {
    id: 4,
    description: 'premium for sharing',
    code: 'PREMIUM_FOR_SHARING',
  },
  PREMIUM_FOR_SA: {
    id: 5,
    description: 'premium for sa',
    code: 'PREMIUM_FOR_SA',
  },
  PREMIUM_FOR_SHARING_SA: {
    id: 6,
    description: 'premium for sharing sa',
    code: 'PREMIUM_FOR_SHARING_SA',
  },
  PREMIUM_FOR_IRAQ: {
    id: 7,
    description: 'premium for iraq',
    code: 'PREMIUM_FOR_IRAQ',
  },
  PREMIUM_FOR_SHARING_IRAQ: {
    id: 8,
    description: 'premium for sharing iraq',
    code: 'PREMIUM_FOR_SHARING_IRAQ',
  },
};

export const MESSAGE_TYPES = {
  NETFLIX_SUBSCRIPTION: {
    id: 1,
    description: 'Message sent 5 days before end of subscription(1 time)',
    code: 'NETFLIX_SUBSCRIPTION',
  },
  NETFLIX_SUBSCRIPTION_REMINDER: {
    id: 2,
    description:
      'Message sent between 5 and 1 day before subscription end (3 times)',
    code: 'NETFLIX_SUBSCRIPTION_REMINDER',
  },
  NETFLIX_PRE_SUBSCRIPTION_END: {
    id: 3,
    description: 'Message sent 1 day before subscription end (1 time)',
    code: 'NETFLIX_PRE_SUBSCRIPTION_END',
  },
};

export const OSTORIES_DOMAINS = {
  NETFLIX: {
    id: 1,
    domain: 'netflixlb.com',
    code: 'NETFLIX',
  },
  TVLEB: {
    id: 2,
    domain: 'tvleb.com',
    code: 'TVLEB',
  },
  OSTORIES: {
    id: 3,
    domain: 'ostories.me',
    code: 'OSTORIES',
  },
  TVJEDDAH: {
    id: 4,
    domain: 'tvjeddah.com',
    code: 'TVJEDDAH',
  },
  STREAMINGLB: {
    id: 5,
    domain: 'streaminglb.com',
    code: 'STREAMINGLB',
  },
  DEFAULT: {
    id: 6,
    domain: 'ostories.me',
    code: 'DEFAULT',
  },
};

export const DEALER_NETFLIX_ACCOUNT_TYPES = {
  PREMIUM: {
    id: 1,
    description: 'Premium',
    code: 'PREMIUM',
  },
  PREMIUM_FOR_SA: {
    id: 5,
    description: 'Premium',
    code: 'PREMIUM',
  },
};
export const NETFLIX_ACCOUNT_TYPES_VALUE_KEY = {
  1: {
    id: 1,
    description: 'Premium',
    code: 'PREMIUM',
  },
  2: {
    id: 2,
    description: 'Standard',
    code: 'STANDARD',
  },
  3: {
    id: 3,
    description: 'Basic',
    code: 'BASIC',
  },
  5: {
    id: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SA.id,
    description: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SA.description,
    code: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SA.code,
  },
  7: {
    id: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_IRAQ.id,
    description: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_IRAQ.description,
    code: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_IRAQ.code,
  },
};

export const NETFLIX_ACCOUNT_TYPES_OPTIONS = [
  {
    id: NETFLIX_ACCOUNT_TYPES.PREMIUM.id,
    description: NETFLIX_ACCOUNT_TYPES.PREMIUM.description,
    code: NETFLIX_ACCOUNT_TYPES.PREMIUM.code,
  },
  {
    id: NETFLIX_ACCOUNT_TYPES.STANDARD.id,
    description: NETFLIX_ACCOUNT_TYPES.STANDARD.description,
    code: NETFLIX_ACCOUNT_TYPES.STANDARD.code,
  },
  {
    id: NETFLIX_ACCOUNT_TYPES.BASIC.id,
    description: NETFLIX_ACCOUNT_TYPES.BASIC.description,
    code: NETFLIX_ACCOUNT_TYPES.BASIC.code,
  },
];

export const NETFLIX_ACCOUNT_TYPES_OPTIONS_LABEL_VALUE = [
  {
    label: NETFLIX_ACCOUNT_TYPES.PREMIUM.description,
    value: NETFLIX_ACCOUNT_TYPES.PREMIUM.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.STANDARD.description,
    value: NETFLIX_ACCOUNT_TYPES.STANDARD.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.description,
    value: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.BASIC.description,
    value: NETFLIX_ACCOUNT_TYPES.BASIC.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SA.description,
    value: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SA.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_SA.description,
    value: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_SA.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_IRAQ.description,
    value: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_IRAQ.code,
  },
  {
    label: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_IRAQ.description,
    value: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_IRAQ.code,
  },
];

export const NETFLIX_ACCOUNT_COUNTRY_OF_CREATION_OPTIONS = [
  {
    label: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[1].description,
    value: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[1].code,
  },
  {
    label: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[2].description,
    value: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[2].code,
  },
  {
    label: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[3].description,
    value: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[3].code,
  },
  {
    label: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[4].description,
    value: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[4].code,
  },
  {
    label: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[5].description,
    value: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[5].code,
  },
  {
    label: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[6].description,
    value: NETFLIX_ACCOUNT_COUNTRIES_OF_CREATION_VALUE_KEY[6].code,
  },
];

export const CUSTOMER_STATUSES = {
  ACTIVE: {
    id: 1,
    description: 'Active',
    code: 'ACTIVE',
  },
  INACTIVE: {
    id: 2,
    description: 'Inactive',
    code: 'INACTIVE',
  },
  BANNED: {
    id: 3,
    description: 'Banned',
    code: 'BANNED',
  },
};

export const NETFLIX_ACCOUNT_STATUSES = {
  ACTIVE: {
    id: 1,
    description: 'Active',
    code: 'ACTIVE',
  },
  INACTIVE: {
    id: 2,
    description: 'Inactive',
    code: 'INACTIVE',
  },
};

export const PAYMENT_STATUSES = {
  PENDING: {
    id: 1,
    description: 'pending',
    code: 'PENDING',
  },
  PAID: {
    id: 2,
    description: 'paid',
    code: 'PAID',
  },
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: {
    id: 1,
    description: 'active',
    code: 'ACTIVE',
  },
  EXPIRED: {
    id: 2,
    description: 'expired',
    code: 'EXPIRED',
  },
  CANCELLED: {
    id: 3,
    description: 'cancelled',
    code: 'CANCELLED',
  },
};

export const NOTIFICATION_STATUSES = {
  PENDING: {
    id: 1,
    description: 'pending',
    code: 'PENDING',
  },
  DONE: {
    id: 2,
    description: 'done',
    code: 'DONE',
  },
};

export const NOTIFICATION_STATUSES_VALUE_KEY = {
  1: {
    id: 1,
    description: 'pending',
    code: 'PENDING',
  },
  2: {
    id: 2,
    description: 'done',
    code: 'DONE',
  },
};

export const LOADING_AREAS = {
  TABLE: 'table',
};

export const NETFLIX_SUBSCRIPTION_TYPES_LB = {
  SHARED_1MONTH: {
    id: 3,
    description: 'User 1 Month',
    code: 'SHARED_1MONTH',
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.code,
    isFull: false,
    months: 1,
  },
  SHARED_3MONTHS: {
    id: 4,
    description: 'User 3 Months',
    code: 'SHARED_3MONTHS',
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.code,
    isFull: false,
    months: 3,
  },
  SHARED_6MONTHS: {
    id: 5,
    description: 'User 6 Months',
    code: 'SHARED_6MONTHS',
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.code,
    isFull: false,
    months: 6,
  },
  SHARED_1YEAR: {
    id: 6,
    description: 'User 1 Year',
    code: 'SHARED_1YEAR',
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.code,
    isFull: false,
    months: 12,
  },
  BASIC: {
    id: 1,
    description: 'Private User 1 Month',
    code: 'BASIC',
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING.code,
    isFull: true,
    months: 1,
  },
  FULL: {
    id: 2,
    description: 'Full Premium',
    code: 'FULL',
    accountTypeCode: 'PREMIUM',
    isFull: true,
    months: 1,
  },
  STANDARD: {
    id: 15,
    description: 'Full Standard',
    code: 'STANDARD',
    months: 1,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.STANDARD.code,
    isFull: true,
  },
};
export const NETFLIX_SUBSCRIPTION_TYPES_SA = {
  SHARED_1MONTH_SA: {
    id: 7,
    description: 'Shared 1 Month SA',
    code: 'SHARED_1MONTH_SA',
    months: 1,
    range: 3,
    isFull: false,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_SA.code,
    country: 'SA',
  },
  SHARED_3MONTHS_SA: {
    id: 8,
    description: 'Shared 3 Months SA',
    code: 'SHARED_3MONTHS_SA',
    months: 3,
    range: 6,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_SA.code,
    isFull: false,
    country: 'SA',
  },
  SHARED_6MONTHS_SA: {
    id: 9,
    description: 'Shared 6 Months SA',
    code: 'SHARED_6MONTHS_SA',
    months: 6,
    range: 15,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_SA.code,
    isFull: false,
    country: 'SA',
  },
  SHARED_1YEAR_SA: {
    id: 10,
    description: 'Shared 1 Year SA',
    code: 'SHARED_1YEAR_SA',
    months: 12,
    range: 15,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_SA.code,
    isFull: false,
    country: 'SA',
  },
};
export const NETFLIX_SUBSCRIPTION_TYPES_IRAQ = {
  SHARED_1MONTH_IRAQ: {
    id: 11,
    description: 'Shared 1 Month',
    code: 'SHARED_1MONTH_IRAQ',
    months: 1,
    range: 3,
    isFull: false,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_IRAQ.code,
    country: 'IRAQ',
  },
  SHARED_3MONTHS_IRAQ: {
    id: 12,
    description: 'Shared 3 Months',
    code: 'SHARED_3MONTHS_IRAQ',
    months: 3,
    range: 6,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_IRAQ.code,
    isFull: false,
    country: 'IRAQ',
  },
  SHARED_6MONTHS_IRAQ: {
    id: 13,
    description: 'Shared 6 Months',
    code: 'SHARED_6MONTHS_IRAQ',
    months: 6,
    range: 15,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_IRAQ.code,
    isFull: false,
    country: 'IRAQ',
  },
  SHARED_1YEAR_IRAQ: {
    id: 14,
    description: 'Shared 1 Year',
    code: 'SHARED_1YEAR_IRAQ',
    months: 12,
    range: 15,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_SHARING_IRAQ.code,
    isFull: false,
    country: 'IRAQ',
  },
  FULL_1MONTH_IRAQ: {
    id: 16,
    description: 'Full 1 Month Iraq',
    code: 'FULL_1MONTH_IRAQ',
    months: 1,
    range: 3,
    accountTypeCode: NETFLIX_ACCOUNT_TYPES.PREMIUM_FOR_IRAQ.code,
    isFull: true,
    country: 'IRAQ',
  },
};

export const COMPANY_PHONE_NUMBER = '+96170437307';
