import styled from 'styled-components';

export const CustomerFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.spacing(3)};
  max-width: ${({ maxWidth }: { maxWidth?: number }) => maxWidth}px;

  padding: 10px 0px;
`;

export const FieldsTitle = styled.p``;
