import React from 'react';
import { useSelector } from 'react-redux';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import Badge from '@mui/material/Badge';

const ProcessNotificationButton = ({
  setIsProcessDialogOpen,
}: {
  setIsProcessDialogOpen: (boolean: boolean) => void;
}) => {
  const processes = useSelector((state: any) => state.processes.processes);

  const notificationsNumber = Object.keys(processes).length;

  return (
    <button
      onClick={() => setIsProcessDialogOpen(true)}
      type="button"
      style={{
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        zIndex: '100',
      }}
    >
      <Badge
        badgeContent={notificationsNumber}
        color="secondary"
        style={{ marginRight: '5px' }}
      >
        <NotificationsTwoToneIcon
          style={{
            color: '#db5759',
            scale: '1.5',
            backgroundColor: 'white',
          }}
        />
      </Badge>
    </button>
  );
};

export default ProcessNotificationButton;
