// @ts-nocheck

import './loader.css';

export const LoadingScreen = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};
