export const ADD = 'Add';
export const ADD_DEALER = 'Add dealer';
export const ADD_ACCOUNT = 'Add account';
export const ADD_ACCOUNTS = 'Add accounts';
export const ADD_SHAHID_ACCOUNT = 'Add shahid account';
export const ADD_BALANCE = 'Add balance';
export const UPDATE_DISCOUNT = 'Update discount';
export const ADD_CUSTOMER = 'Add customer';
export const EDIT_CUSTOMER = 'Edit customer';
export const EDIT_DEALER = 'Edit dealer';
export const ADD_EMPLOYEE = 'Add employee';
export const ADD_SUBSCRIPTION = 'Add subscription';
export const CUSTOMER_SUBSCRIPTION = 'Custom Subscription';
export const ACCOUNTS = 'Accounts';
export const ACCOUNTS_PRICE = 'Accounts price';
export const AUTO_RENEW = 'Turn off auto renew';
export const AUTO_RENEW_CONFIRMATION_TEXT =
  'Are you sure you want to turn off automatic renew for this account?';
export const ACCOUNT_RENEWED_SUCCESSFULLY = 'Renewed account successfully';
export const REGION_UPDATED_SUCCESSFULLY = 'Region updated successfully';
export const MONTHS_ADDED_SUCCESSFULLY = (months) =>
  `${months} months added to account successfully`;
export const DAYS_ADDED_SUCCESSFULLY = (months) =>
  `${months} days added to account successfully`;
export const AUTOMATIC = 'Automatic';
export const ASSIGNED_TO = 'Assigned to:';
export const SET_ACCOUNTS_PRICE = 'Set Price';
export const SET_DOLLAR_RATE = 'Set dollar rate';
export const BACK_TO_DEALERS_LIST = 'Back to dealers list';
export const BACK_TO_CUSTOMERS_LIST = 'Back to customers list';
export const BACK_TO_EMPLOYEES_LIST = 'Back to employees list';
export const BAN_CUSTOMER = 'Ban customer';
export const BALANCE = 'Balance';
export const BUY_ACCOUNT = 'Buy account';
export const CUSTOMERS = 'Customers';
export const CANCEL_EXPIRED_ACCOUNTS = 'Cancel expired accounts';
export const NO_ACCOUNTS_TO_CANCEL = 'No accounts to cancel';
export const DEALERS = 'Dealers';
export const DELETE = 'Delete';
export const DELETE_EMPLOYEE = 'Delete employee';
export const DELETE_EMPLOYEE_CONFIRMATION_TEXT =
  'Are you sure you want to delete this employee?';
export const EMPLOYEES = 'Employees';
export const UPDATE_CUSTOMER_STATUS_CONFIRMATION_TEXT =
  'Are you sure you want to update the customer status?';
export const CANCEL = 'Cancel';
export const CHANGE_EMAIL = 'Change Email';
export const EDIT_EMAIL = 'Edit Email';
export const CANCEL_SUBSCRIPTION = 'Cancel subscription';
export const CANCEL_SUBSCRIPTION_CONFIRMED =
  'Subscription cancelled Successfully';
export const CANCEL_SUBSCRIPTION_CONFIRMATION_TEXT =
  'Are you sure you want to cancel this subscription?';
export const CUSTOMER_STATUS = 'Customer status';
export const CUSTOMER_DETAILS = 'Customer details';
export const DEALER_DETAILS = 'Dealer details';
export const USD = 'USD';
export const LBP = 'LBP';
export const SUBSCRIPTION = 'Subscription';
export const EXTEND_5_DAYS = 'Extend 5 days';
export const EMPLOYEE_DETAILS = 'Employee details';
export const REDUCE_5_DAYS = 'Reduce 5 days';
export const EXTEND_SUBSCRIPTION = 'Extend subscription';
export const EXTEND_CONFIRMATION_TEXT =
  'Are you sure you want to extend the subscription for 5 days?';
export const EXPIRED_ACCOUNTS = 'Expired accounts';
export const EXPIRED_ACCOUNT_DEALER_MESSAGE =
  "Expired account, it will be deleted if you don't renew it in one day";

export const ACTIVE_ACCOUNT_DEALER_MESSAGE = 'Active account';
export const NOT_ENOUGH_BALANCE_DEALER_MESSAGE =
  "Expired account, but you don't have enough balance to renew it";
export const ADD_MONTHS = 'Add months';
export const CHANGE_REGION = 'Change Region';
export const EDIT = 'Edit';
export const CHANGE = 'Change';
export const ADD_DAYS = 'Add days';
export const FIX_PASSWORD = 'Fix password';
export const FIX_PASSWORD_CONFIRMATION_TEXT =
  " Are you sure you want to fix this account's password?";
export const UPDATE_PAYMENT_STATUS = 'Update payment status';
export const UPDATE_PAYMENT_STATUS_TO_PAID = 'Update payment status to paid';
export const UPDATE_PAYMENT_STATUS_TO_PENDING =
  'Update payment status to pending';
export const UPDATE_PAYMENT_STATUS_CONFIRMATION_TEXT =
  'Are you sure you want to update the payment status?';
export const CHANGE_PASSWORD = 'Change password';
export const SETTINGS = 'Settings';
export const CHANGED_PASSWORD_SUCCESS = 'Password changed successfully';
export const RESET_LINK_SENT = 'Reset Link Sent';
export const RESET_LINK_ERROR = 'Error when getting reset link';
export const RENEW_SUBSCRIPTION = 'Renew subscription';
export const RENEW_ACCOUNT = 'Renew Account';
export const RENEW = 'Renew';
export const REDUCE_CONFIRMATION_TEXT =
  'Are you sure you want to reduce the subscription for 5 days?';
export const REMOVE_BAN = 'Remove ban';
export const CANCEL_EXPIRED_ACCOUNT = 'Cancel expired account';
export const CANCEL_ACCOUNT = 'Cancel account';
export const CANCEL_EXPIRED_ACCOUNT_CONFIRMED = 'Account canceled successfully';
export const CANCEL_EXPIRED_ACCOUNT_TEXT =
  'Are you sure you want to cancel this expired account?\nThis will return the account to stock. ';
export const SIGN_OUT_EXPIRED_ACCOUNT_TEXT =
  'Are you sure you want to sign out this expired account from all devices?';
export const SIGN_OUT_AND_CANCEL_EXPIRED_ACCOUNT_CONFIRMED =
  'Account signed out and canceled successfully';
export const SIGN_OUT_AND_CANCEL_EXPIRED_ACCOUNT_TEXT =
  'Are you sure you want to sign out and cancel this expired account? This will sign out the account from all devices and return it to stock.';
export const SIGN_OUT_AND_CANCEL_EXPIRED_ACCOUNT = 'Sign out and Cancel';
export const SIGN_OUT_EXPIRED_ACCOUNT = 'Sign out expired account';
export const SIGN_OUT_EXPIRED_ACCOUNT_CONFIRMED =
  'Account signed out successfully';
export const SIGN_OUT_EXPIRED_ACCOUNTS = 'Sign out expired accounts';
export const SOON_EXPIRED = 'Soon Expired';
export const SOMETHING_WENT_WRONG_REFRESH =
  'Something went wrong please refresh';
export const NO_SUBSCRIPTIONS = 'No subscriptions';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const STORE_DETAILS = 'Store details';
export const STOCK = 'Stock';
export const NETFLIX_STOCK_TEXT = 'Netflix Stock';
export const ANGHAMI_STOCK_TEXT = 'Anghami Stock';

export const SHAHID_STOCK_TEXT = 'Shahid';
export const SPOTIFY_TEXT = 'Spotify';
export const SUBSCRIPTIONS = 'Subscriptions';
export const SHAHID_SUBSCRIPTIONS_TEXT = 'Subscriptions';
export const SET = 'Set';
export const TRANSACTIONS = 'Transactions';
export const LOGIN_FORM_TITLE = 'Sign in to your account';
export const LOGIN_FORM_SUBTITLE = ' Welcome to TvLeb, contact us for help:';
export const LOGGED_IN_SUCCESSFULLY = 'Logged in successfully';
export const VALIDATION_ERROR_MESSAGE_REQUIRED = 'Required field';
export const VALIDATION_ERROR_EXPIRY_DATE_MIN =
  'Expiry date is invalid, must be in the future';
export const VALIDATION_ERROR_INVALID_DATE = 'Invalid date';
export const VALIDATION_ERROR_REQUIRED_PHONE = 'Phone is required';
export const VALIDATION_ERROR_POSITIVE_NUMBER = 'Must be a positive number';
export const VIEW = 'View';
export const VIEW_EXPIRED_ACCOUNTS = 'View expired accounts';
export const VIEW_SUBSCRIPTIONS = 'View subscriptions';
export const VIEW_HISTORY = 'View history';
export const INCORRECT_USERNAME_OR_PASSWORD =
  'Login failed! Incorrect username or password.';
export const LOGIN_NETWORK_ERROR =
  'Technical incident, please try again later.';
export const OPERATIONS = 'Operations';
export const WHATSAPP = 'Whatsapp';
export const PAGE_NOT_FOUND_TITLE = '404';
export const PROFILE = 'Profile';
export const PAID = 'Paid';
export const PAYMENT_STATUS = 'Payment status';
export const SAVE = 'Save';
export const SHOW_ONLY_ASSIGNED_TO_ME = 'Show only assigned to me';
export const SHOW_ONLY_TODAY = 'Show only today';
export const LOGOUT = 'Logout';
export const YES = 'Yes';
export const WHISH = 'Whish';
export const ACTION_HISTORY = 'Actions History';
export const ANNOUNCEMENTS = 'Announcements';
export const ANALYTICS_PAGE = 'Analytics';
