// @ts-nocheck

import axios from 'axios';
import URLs from '../../config/api-endpoints';
import { getAuthToken } from '../../utils/session';

const getDealerBalance = (dealerId: number): Promise<any> => {
  const jwt = getAuthToken();
  const url = URLs.DEALERS.GET_DEALER_BALANCE(dealerId);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export default getDealerBalance;
