import { useDispatch, useSelector } from 'react-redux';
import React, {
  useEffect,
  lazy,
  Suspense,
  LazyExoticComponent,
  FC,
} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { USER_TYPES } from '../config/globals';

import {
  ACTIONS_HISTORY,
  ADD_CUSTOMER,
  ADD_DEALER,
  ADD_EMPLOYEE,
  ALL_PATHS,
  ANGHAMI,
  CUSTOMERS,
  DEALERS,
  EMPLOYEES,
  ERROR,
  INDEX,
  LOGIN,
  NETFLIX_STOCK,
  OPERATIONS,
  ORDERS,
  REROUTE,
  SETTINGS,
  SHAHID_STOCK,
  SHAHID_SUBSCRIPTIONS,
  SPOTIFY,
  SUBSCRIPTIONS_HISTORY,
  TRANSACTIONS,
  URL_LOGIN,
  WHATSAPP,
  WHISH,
  ANNOUNCEMENT,
  ANALYTICS,
} from './routes';
import { LoadingScreen } from '../pages/re-route/loadingScreen';
import { setUser } from '../redux/slices/user';
import Layout from '../containers/layout';
// import Filter from '../pages/filteringNetflixAccounts';

const SubscriptionHistory = lazy(() => import('../pages/subscriptionHistory'));
const ShahidSubscriptions = lazy(() => import('../pages/subscriptions'));
const Transactions = lazy(() => import('../pages/transactions'));
const TransactionsDealer = lazy(
  () => import('../pages/transactions-dealer-view'),
);
const Dealers = lazy(() => import('../pages/dealers/dealers-list'));
const AddDealer = lazy(() => import('../pages/dealers/add-dealer'));
const Accounts = lazy(() => import('../pages/accounts'));
const AccountsDealerView = lazy(
  () => import('../pages/accounts/accounts-dealer-view'),
);
const Anghami = lazy(() => import('../pages/anghami'));
const Customers = lazy(() => import('../pages/customers'));
const AddCustomer = lazy(
  () => import('../pages/customers/dialogs/add-customer'),
);
const Orders = lazy(() => import('../pages/orders'));
// const Subscriptions = lazy(() => import('../pages/subscriptions'));
const AddEmployee = lazy(() => import('../pages/employees/add-employee'));
const Employees = lazy(() => import('../pages/employees/employees-list'));
const Operations = lazy(() => import('../pages/operations/operations-list'));
const Index = lazy(() => import('../containers/index-page'));
const Spotify = lazy(() => import('../pages/spotify'));
const Login = lazy(() => import('../pages/login'));
const PageNotFound = lazy(() => import('../pages/page-not-found'));
const WhishTransactions = lazy(() => import('../pages/wish-transactions'));
const UrlLogin = lazy(() => import('../pages/url-login'));
const ReRoute = lazy(() => import('../pages/re-route'));
const Settings = lazy(() => import('../pages/settings'));
const Whatsapp = lazy(() => import('../pages/whatsapp'));
const ShahidStock = lazy(() => import('../pages/shahid/accounts'));
const Announcements = lazy(() => import('../pages/announcements/index'));
const History = lazy(() => import('../pages/history/index'));
const Analytics = lazy(() => import('../pages/analytics/index'));

const commonRoutes = [
  {
    path: INDEX,
    component: Index,
  },
  {
    path: ERROR,
    component: PageNotFound,
  },
  {
    path: URL_LOGIN,
    component: UrlLogin,
  },
  // {
  //   path: ALL_PATHS,
  //   component: PageNotFound,
  // },
];

const adminRoutes = [
  {
    path: SUBSCRIPTIONS_HISTORY,
    component: SubscriptionHistory,
  },
  // {
  //   path: 'filter',
  //   component: Filter,
  // },
  {
    path: SHAHID_SUBSCRIPTIONS,
    component: ShahidSubscriptions,
  },
  {
    path: ANGHAMI,
    component: Anghami,
  },
  {
    path: REROUTE,
    component: ReRoute,
  },
  {
    path: WHISH,
    component: WhishTransactions,
  },
  {
    path: TRANSACTIONS,
    component: Transactions,
  },
  {
    path: ORDERS,
    component: Orders,
  },
  {
    path: DEALERS,
    component: Dealers,
  },
  {
    path: ADD_DEALER,
    component: AddDealer,
  },
  {
    path: NETFLIX_STOCK,
    component: Accounts,
  },
  {
    path: CUSTOMERS,
    component: Customers,
  },
  {
    path: ADD_CUSTOMER,
    component: AddCustomer,
  },
  {
    path: SPOTIFY,
    component: Spotify,
  },
  // {
  //   path: SUBSCRIPTIONS,
  //   component: Subscriptions,
  // },
  // {
  //   path: CUSTOMER_SUBSCRIPTIONS,
  //   component: Subscriptions,
  // },
  {
    path: ADD_EMPLOYEE,
    component: AddEmployee,
  },
  {
    path: EMPLOYEES,
    component: Employees,
  },
  {
    path: OPERATIONS,
    component: Operations,
  },
  {
    path: WHATSAPP,
    component: Whatsapp,
  },
  {
    path: SHAHID_STOCK,
    component: ShahidStock,
  },
  {
    path: SETTINGS,
    component: Settings,
  },
  {
    path: ACTIONS_HISTORY,
    component: History,
  },
  {
    path: ANNOUNCEMENT,
    component: Announcements,
  },
  {
    path: ANALYTICS,
    component: Analytics,
  },
];

const employeeRoutes = [
  {
    path: SUBSCRIPTIONS_HISTORY,
    component: SubscriptionHistory,
  },

  {
    path: OPERATIONS,
    component: Operations,
  },
  {
    path: ANGHAMI,
    component: Anghami,
  },
  {
    path: ACTIONS_HISTORY,
    component: History,
  },
  {
    path: SHAHID_STOCK,
    component: ShahidStock,
  },
  {
    path: REROUTE,
    component: ReRoute,
  },
  {
    path: SPOTIFY,
    component: Spotify,
  },
  {
    path: WHISH,
    component: WhishTransactions,
  },
  {
    path: TRANSACTIONS,
    component: Transactions,
  },
  {
    path: DEALERS,
    component: Dealers,
  },
  {
    path: ADD_DEALER,
    component: AddDealer,
  },
  {
    path: NETFLIX_STOCK,
    component: Accounts,
  },
  {
    path: CUSTOMERS,
    component: Customers,
  },
  {
    path: ADD_CUSTOMER,
    component: AddCustomer,
  },
  // {
  //   path: SUBSCRIPTIONS,
  //   component: Subscriptions,
  // },
  // {
  //   path: CUSTOMER_SUBSCRIPTIONS,
  //   component: Subscriptions,
  // },
  {
    path: SETTINGS,
    component: Settings,
  },
  {
    path: WHATSAPP,
    component: Whatsapp,
  },
  {
    path: SHAHID_SUBSCRIPTIONS,
    component: ShahidSubscriptions,
  },
];

const dealerRoutes = [
  {
    path: SUBSCRIPTIONS_HISTORY,
    component: SubscriptionHistory,
  },
  {
    path: REROUTE,
    component: ReRoute,
  },
  {
    path: TRANSACTIONS,
    component: TransactionsDealer,
  },
  {
    path: CUSTOMERS,
    component: Customers,
  },
  {
    path: ADD_CUSTOMER,
    component: AddCustomer,
  },
  {
    path: NETFLIX_STOCK,
    component: AccountsDealerView,
  },

  // {
  //   path: SUBSCRIPTIONS,
  //   component: Subscriptions,
  // },
  // {
  //   path: CUSTOMER_SUBSCRIPTIONS,
  //   component: Subscriptions,
  // },
  {
    path: SHAHID_SUBSCRIPTIONS,
    component: ShahidSubscriptions,
  },
];

const routes = (type = 'default'): { path: string; component: FC }[] => {
  const allRoutes: {
    [key: string]: { path: string; component: LazyExoticComponent<FC> }[];
  } = {
    [USER_TYPES.DEFAULT]: [...commonRoutes],
    [USER_TYPES.ADMIN]: [...commonRoutes, ...adminRoutes],
    [USER_TYPES.EMPLOYEE]: [...commonRoutes, ...employeeRoutes],
    [USER_TYPES.DEALER]: [...dealerRoutes, ...commonRoutes],
  };

  if (allRoutes[type] === undefined) {
    return allRoutes.default;
  }

  return allRoutes[type]?.map(
    (route: { path: string; component: LazyExoticComponent<FC> }) => {
      return {
        path: route.path,
        component: () => (
          <Suspense fallback={<LoadingScreen />}>
            {route.component ? React.createElement(route.component) : ''}
          </Suspense>
        ),
      };
    },
  );
};

const RoutesComponent: FC = () => {
  const userData = useSelector(
    (state: {
      user: {
        userType: string;
        userInfo: { [key: string]: string | [] | boolean | object };
        token: string;
        isJustLoggedIn: boolean;
      };
    }) => state.user,
  );
  const { isJustLoggedIn, userType } = userData;
  const { token: authed } = userData;
  const dispatch = useDispatch();
  useEffect(() => {
    if (isJustLoggedIn) {
      setTimeout(() => {
        dispatch(setUser({ ...userData?.userInfo, isJustLoggedIn: false }));
      }, 200);
    }
  }, [isJustLoggedIn]);

  // useEffect(() => {
  //   const checkUnderMaintenance = async () => {
  //     try {
  //       const response = await getIfUnderMaintenance();
  //       setIsUnderMaintenance(!!response.data?.underMaintenance);
  //       setIsLoading(false);
  //     } catch (e) {
  //       // eslint-disable-next-line no-console
  //       setIsLoading(false);
  //     }
  //     setTimeout(() => {
  //       checkUnderMaintenance();
  //     }, 60000);
  //   };
  //   checkUnderMaintenance();
  // }, []);

  return (
    <Router>
      <Routes>
        {(!authed || isJustLoggedIn) && (
          <>
            <Route
              path={LOGIN}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path={ERROR}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <PageNotFound />
                </Suspense>
              }
            />
            <Route
              path={URL_LOGIN}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <UrlLogin />
                </Suspense>
              }
            />
            <Route
              path={ALL_PATHS}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Login />
                </Suspense>
              }
            />
          </>
        )}

        {authed &&
          !isJustLoggedIn &&
          routes(USER_TYPES[userType]).map((route, index) => (
            <Route
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              path={route?.path}
              element={
                <Layout>
                  <route.component />
                </Layout>
              }
            />
          ))}
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
