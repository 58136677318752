import { AccordionActions, Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { deleteProcess } from '../../../redux/slices/backgroundProcess';

interface DeleteButtonProps {
  processId: string;
}

const AccordionDeleteButton: FC<DeleteButtonProps> = ({ processId }) => {
  const dispatch = useDispatch();
  const processes = useSelector((state: any) => state.processes.processes);
  const [isDeleteText, setIsDeleteText] = useState<boolean>(false);

  const handleDelete = (id: string) => {
    dispatch(deleteProcess({ processId: id }));
  };
  return (
    <AccordionActions>
      {isDeleteText ? (
        <>
          <Button sx={{ color: 'grey' }} onClick={() => setIsDeleteText(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(processId)}>Delete</Button>
        </>
      ) : (
        <Button onClick={() => setIsDeleteText(true)}>
          <DeleteOutlineOutlinedIcon
            style={{
              color: '#db5759 ',
              verticalAlign: 'middle',
            }}
          />
        </Button>
      )}
    </AccordionActions>
  );
};

export default AccordionDeleteButton;
