export const ERROR = '/page-not-found';
export const LOGIN = '/';
export const INDEX = '/';
export const ALL_PATHS = '/*';
export const WHISH = '/whish';
export const DEALERS = '/dealers';
export const EMPLOYEES = '/employees';
export const ORDERS = '/orders';
export const TRANSACTIONS = '/transactions';
export const ADD_EMPLOYEE = '/add-employee';
export const ADD_DEALER = '/add-dealer';
export const ACCOUNTS = '/accounts';
export const CUSTOMERS = '/customers';
export const ADD_CUSTOMER = '/add-customer';
export const SPOTIFY = '/spotify';
export const CUSTOMER_SUBSCRIPTIONS = '/subscriptions/:id';
// export const SUBSCRIPTIONS = '/subscriptions';
export const NETFLIX_STOCK = '/netflix/stock';
export const SHAHID_STOCK = '/shahid/stock';
export const SHAHID_SUBSCRIPTIONS = '/subscriptions';
export const OPERATIONS = '/operations';
export const SUBSCRIPTIONS_HISTORY = '/subHistory';
export const ACTIONS_HISTORY = '/actionsHistory';
export const ANNOUNCEMENT = '/announcements';
export const ANGHAMI = '/anghami';
export const WHATSAPP = '/whatsapp';
export const SETTINGS = '/settings';
export const URL_LOGIN = '/urlLogin';
export const REROUTE = '/';
export const ANALYTICS = '/analytics';

// DYNAMIC ROUTES
export const DYNAMIC_SUBSCRIPTIONS = (phone: string) =>
  `/subscriptions?searchKey=${phone}`;
