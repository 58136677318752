import React from 'react';
import './styles.css';

// create a Announcement component with a children prop parameter
const Announcement = ({
  children,
  onClose,
}: {
  children: any;
  onClose: () => void;
}) => {
  return (
    <div className="announcement-bar-container">
      <div className="moving-animation">{children}</div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <span className="close-button" onClick={onClose}>
        X
      </span>
    </div>
  );
};

export default Announcement;
