import styled from 'styled-components';

export const UlContainer = styled.ul`
  list-style: none;
  width: '100%';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'flex-start';
`;

export const LiContainer = styled.li`
  width: 100%;
  margin-bottom: 10px;
`;

export const PContainer = styled.p`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
`;
