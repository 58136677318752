// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { MenuOpenRounded, MenuRounded } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FlagIcon } from 'react-flag-kit';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Navbar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import getDealerBalanceApi from '../../../api/dealers/get-dealer-balance';

import { LOGIN } from '../../../router/routes';
import {
  logout,
  setDealerBalance,
  toggleSidebar,
  changeLanguage,
} from '../../../redux/slices/user';
import { USER_TYPES } from '../../../config/globals';
import getSettings from '../../../api/settings/get-settings';
import ChangePasswordDialog from '../../../components/action-popup/changePasswordDialog';
import getPendingAccountsCount from '../../../api/pending-accounts/get-pending-accounts-count';
import { CHANGE_PASSWORD, SETTINGS } from '../../../config/strings';
import CustomSelect from '../../../components/newDesignActionMenu';

const Header = (): React.ReactElement<any> => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(null);
  const [pendingCount, setPendingCount] = useState(0);
  const user = useSelector((state) => state.user);
  const { isSidebarCollapsed, userType, language } = user;
  const isDealer = userType === USER_TYPES.DEALER;
  const isAdmin = userType === USER_TYPES.ADMIN;
  const dealerId = user?.dealerInfo?.id;
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState<boolean>(false);

  const getDealerBalance = async () => {
    try {
      const result = await getDealerBalanceApi(dealerId);
      dispatch(setDealerBalance(result.data.balance.toFixed(2)));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSettings = async () => {
    const settingsResponse = await getSettings();
    setSettings(settingsResponse.data.settings);
  };

  const fetchPendingAccountsCount = async () => {
    const response = await getPendingAccountsCount();
    setPendingCount(response.data.count);
  };

  useEffect(() => {
    const getHeaderData = () => {
      if (isDealer) {
        getDealerBalance();
      }
      if (isAdmin) {
        fetchPendingAccountsCount();
      }
      fetchSettings();
    };

    getHeaderData();

    if (!isDealer) {
      setInterval(() => {
        getHeaderData();
      }, 300000); // 30 seconds in milliseconds
    }
  }, []);

  const rows = {
    password: () => setIsChangePasswordDialogOpen(true),
    settings: () => navigate('/settings'),
    logout: () => {
      dispatch(logout());
      navigate(LOGIN);
    },
  };

  const handleSelectMenu = (selected: any) => {
    rows[selected]();
    setAnchorEl(null);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    dispatch(changeLanguage({ lang: selectedLanguage }));
  };

  return (
    <Navbar
      position="sticky"
      sx={{
        background: '#232524',
        boxShadow: '3px 3px 3px #00000095',
        height: '50px',
      }}
    >
      <Toolbar
        style={{
          padding: '5px',
          height: '50px',
          minHeight: '50px',
          maxHeight: '50px',
        }}
      >
        <IconButton
          sx={{
            color: 'white',
            padding: '0px',
            marginLeft: '5px',
            marginRight: '10px',
          }}
          onClick={() => dispatch(toggleSidebar())}
        >
          {isSidebarCollapsed ? <MenuRounded /> : <MenuOpenRounded />}
        </IconButton>
        <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
          Rate: ${settings?.USD_TO_LBP}
          {isAdmin && <span>{` Pending: ${pendingCount}`}</span>}
        </Typography>
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          {/* {isAdmin && (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Lang
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                size="small"
                value={language.lang}
                onChange={handleLanguageChange}
                sx={{ color: 'white', borderRadius: '5px' }}
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <MenuItem value="en-us">
                  <span
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <FlagIcon
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      code="US"
                    />
                    EN
                  </span>
                </MenuItem>
                <MenuItem value="ar-sa">
                  <span
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <FlagIcon
                      code="SA"
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          )} */}
          {isDealer && (
            <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
              Balance: {user.dealerInfo.balance}$
            </Typography>
          )}

          <Avatar
            sx={{
              color: 'black',
              cursor: 'pointer',
              width: '30px',
              height: '30px',
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {user.firstName.split('')[0]}
          </Avatar>
        </div>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <ButtonGroup orientation="vertical">
            <Button onClick={() => handleSelectMenu('password')}>
              {CHANGE_PASSWORD}
            </Button>
            {isAdmin && (
              <Button onClick={() => handleSelectMenu('settings')}>
                {SETTINGS}
              </Button>
            )}
            <Button onClick={() => handleSelectMenu('logout')}>Logout</Button>
          </ButtonGroup>
        </Popover>
      </Toolbar>

      <ChangePasswordDialog
        isOpen={isChangePasswordDialogOpen}
        onClose={() => setIsChangePasswordDialogOpen(false)}
      />
    </Navbar>
  );
};

export default Header;
