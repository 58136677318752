// @ts-nocheck

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import {
  CHANGED_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
} from '../../config/strings';

import changeUserPassword from '../../api/user/change-password';

import { CustomerFieldsContainer } from '../../pages/dealers/dealers-list/add-shahid-to-dealer/styles';
import CustomDialogActions from '../custom-dialog-actions';

const ChangePasswordDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): React.ReactElement<any> => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [data, setData] = useState({
    password: '',
    oldPassword: '',
    passwordConfirm: '',
  });

  const handleClose = () => {
    onClose();
    setData({
      oldPassword: '',
      passwordConfirm: '',
      password: '',
    });
  };
  const isStrongPassword = (passwordToTest: string) => {
    const strongPasswordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    return strongPasswordRegex.test(passwordToTest);
  };
  const handleChangePassword = async () => {
    if (!isStrongPassword(data.password)) {
      return toast.error(
        'Password should contain at least 1 letter and 1 number',
      );
    }
    if (data.password !== data.passwordConfirm) {
      return toast.error('Password Does Not Match');
    }
    if (data.password.trim().length > 6) {
      try {
        setIsDisabled(true);

        await changeUserPassword(data);
        setIsDisabled(false);
        handleClose();
        return toast.success(CHANGED_PASSWORD_SUCCESS);
      } catch (e) {
        setIsDisabled(false);
        return toast.error(e.response.data.message);
      }
    } else {
      return toast.error('Password is too small');
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{CHANGE_PASSWORD}</DialogTitle>
      <DialogContent>
        <CustomerFieldsContainer>
          <TextField
            label="Old Password"
            type="password"
            variant="outlined"
            value={data.oldPassword}
            size="small"
            onChange={(e) => setData({ ...data, oldPassword: e.target.value })}
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            value={data.password}
            size="small"
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            value={data.passwordConfirm}
            size="small"
            onChange={(e) =>
              setData({ ...data, passwordConfirm: e.target.value })
            }
          />
        </CustomerFieldsContainer>
      </DialogContent>
      <CustomDialogActions
        onClose={handleClose}
        onConfirm={handleChangePassword}
        isDisabled={isDisabled}
        isLoading={isDisabled}
        confirmText="Change Password"
      />
    </Dialog>
  );
};

export default ChangePasswordDialog;
