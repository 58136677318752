// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: null,
  token: null,
  isSidebarCollapsed: false,
  dealerInfo: null,
  language: {
    lang: 'en-us',
    dir: 'ltr',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },

    setToken: (state, action) => {
      return { ...state, token: action.payload };
    },

    logout: () => {
      return initialState;
    },

    toggleSidebar: (state) => {
      return { ...state, isSidebarCollapsed: !state.isSidebarCollapsed };
    },

    changeLanguage: (state, action) => {
      const { lang } = action.payload;

      let dir = 'ltr';

      // Set direction based on language
      if (lang.startsWith('ar')) {
        dir = 'rtl';
      }

      // Update language state
      return { ...state, language: { lang, dir } };
    },

    openSidebar: (state) => {
      return { ...state, isSidebarCollapsed: false };
    },
    closeSidebar: (state) => {
      return { ...state, isSidebarCollapsed: true };
    },

    setDealerAccountsPrice: (state, action) => {
      return {
        ...state,
        dealerInfo: {
          ...state.dealerInfo,
          prices: action.payload,
        },
      };
    },

    setDealerBalance: (state, action) => {
      return {
        ...state,
        dealerInfo: {
          ...state.dealerInfo,
          balance: action.payload,
        },
      };
    },
  },
});

export const {
  setUser,
  setToken,
  logout,
  toggleSidebar,
  setDealerAccountsPrice,
  setDealerBalance,
  openSidebar,
  changeLanguage,
  closeSidebar,
} = userSlice.actions;
export const selectUserRole = (state) => state.user.role;

export default userSlice.reducer;
