const codes = {
  SUCCESS: 200,
  SUCCESS_CREATED: 201,
  SUCCESS_NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  CONFLICT: 409,
  LOCKED: 423,
  SERVER_ERROR: 500,
  DATA_EXPIRED: 422,
  VALIDATION_ERROR: 400,
};
export const SUCCESS = 200;
export const SUCCESS_CREATED = 201;
export const SUCCESS_NO_CONTENT = 204;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const NOT_ACCEPTABLE = 406;
export const CONFLICT = 409;
export const LOCKED = 423;
export const SERVER_ERROR = 500;
export const DATA_EXPIRED = 422;
export const VALIDATION_ERROR = 400;

export default codes;
