import React, { ReactElement } from 'react';
import { Button } from '@mui/joy';
import { StyledButtonProps } from './flow-types';

const StyledButton = ({
  children,
  text,
  onClick,
  color,
  type,
  variant,
  startIcon,
  endIcon,
  size,
  style,
  sx,
  form,
  isDisabled,
  autoFocus,
  isLoading,
  fullWidth,
}: StyledButtonProps): ReactElement => {
  return (
    <Button
      onClick={onClick}
      type={type}
      variant={variant}
      startDecorator={startIcon}
      endDecorator={endIcon}
      sx={sx}
      style={style}
      size={size}
      form={form}
      disabled={isDisabled}
      loading={isLoading}
      autoFocus={autoFocus}
      color={color}
      fullWidth={fullWidth}
    >
      {children || text}
    </Button>
  );
};

StyledButton.defaultProps = {
  text: '',
  onClick: null,
  type: 'button',
  variant: 'solid',
  startIcon: null,
  endIcon: null,
  size: 'sm',
  style: {},
  sx: {},
  form: '',
  isDisabled: false,
  autoFocus: false,
  isLoading: false,
  fullWidth: false,
  color: 'primary',
};

export default StyledButton;
