import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Megaphone from '../../assets/megaphone.png';

import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
  MegaPhoneImage,
} from './styles';

const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
  },
});

const AnnouncementDialog = ({
  isOpen,
  onClose,
  announcementId,
  title,
  content,
  isArabic,
}: {
  isOpen: boolean;
  onClose: (id: string, isDontShowAgain: boolean) => void;
  announcementId: string;
  title: string;
  content: string;
  isArabic: boolean;
}) => {
  const [isDontShowAgain, setIsDontShowAgain] = useState(false);

  const handleClose = () => {
    onClose(announcementId, isDontShowAgain);
  };

  return (
    isOpen && (
      <Backdrop>
        <Dialog>
          <DialogTitle
            style={{
              direction: isArabic ? 'rtl' : 'ltr',
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            style={{
              direction: isArabic ? 'rtl' : 'ltr',
            }}
          >
            {content.split('\n').map((line) => (
              <span
                key={line}
                style={{ display: 'block', marginBottom: '0.5rem' }}
              >
                {line}
              </span>
            ))}
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <FormControlLabel
                style={{ color: '#fff' }}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlank style={{ fill: '#fff' }} />}
                    checkedIcon={<CheckBoxOutlined style={{ fill: '#fff' }} />}
                    style={{ fill: 'white' }}
                    onChange={(_, checked) => setIsDontShowAgain(checked)}
                    checked={isDontShowAgain}
                  />
                }
                label="Don't show again"
              />
            </ThemeProvider>
            <DialogButton onClick={handleClose}>Close</DialogButton>
          </DialogActions>
        </Dialog>
        <MegaPhoneImage src={Megaphone} alt="megaphone" />
      </Backdrop>
    )
  );
};

export default AnnouncementDialog;
