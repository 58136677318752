import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *,
    *::after,
    *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;

    }
    body {
        declare module 'styled-components' {
          export interface DefaultTheme {
            colors: {
              greyScaleDarkGrey850: string;
            };
          }
        }

        color: ${({ theme }: { theme: any }) =>
          theme.colors.greyScaleDarkGrey850};
        font-family: 'Roboto', sans-serif;
        letter-spacing: .6px;

    }

p {
  line-height: 1.44;
  margin: 0 !important;
}


body {
  overflow-x: hidden;
}

a,
a:hover {
  cursor: pointer;
  text-decoration: none;
}
.button,
a,
button {
  transition: all 0.3s ease-out 0s;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
ul {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-position: outside;
  margin-left: 1em;
}

dl,
ol,
ul {
  margin-top: 0;
}

button:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}



*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.primary2};
  }
}

.MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) =>
      theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-standard {
    border-color: ${({ theme }) =>
      theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiInputLabel-standard.MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiInputLabel-standard.MuiInputLabel-root {
    color: ${({ theme }) => theme.primary} !important;
  }
  .MuiOutlinedInput-standard.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) =>
      theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiOutlinedInput-standard .MuiOutlinedInput-standard {
    border-color: ${({ theme }) =>
      theme.colors.greyScaleDarkGrey850} !important;
  }
  .MuiInputLabel-standard.MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.greyScaleDarkGrey850} !important;
  }

  .MuiInputBase-input {
    font-size: 14px !important;
  }

  .MuiInputLabel-root {
    font-size: 14px !important;
  }

  // disabled input style 
  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${({ theme }) =>
      theme.colors.greyScaleDarkGrey850} !important;
  }

  /* .MuiChip-label{
    color: ${({ theme }) => theme.colors.primaryColor} !important;
  }

  .MuiChip-filled{
    background-color: ${({ theme }) => theme.colors.primaryColor7} !important;
  } */

  // material ui toggle button style
  .MuiToggleButton-root {
   
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryColor6} !important;
    }
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: ${({ theme }) => theme.colors.primaryColor6} !important;
 
  }

  .MuiToggleButtonGroup-groupedHorizontal { 
    color: ${({ theme }) => theme.colors.greyScaleDarkGrey850} !important;
  }
  
  .expired-row {
    background-color: ${({ theme }) => theme.colors.expiredColor} !important;
  }

  .cancelled-row {
    background-color: ${({ theme }) => theme.colors.primaryColor7} !important;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  
  .MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.primaryColor2} !important;
  }

  // material switch
  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.colors.primaryColor2} !important;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.primaryColor2} !important;
  } 

`;
