import { SvgIcon } from '@mui/material';
import React from 'react';
import { shahid } from '../../../../assets';

const ShahidIcon = ({ props }: { props: any }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient id="MyGradient">
          <stop offset="5%" stopColor="#00d358" />
          <stop offset="95%" stopColor="#0099ff" />
        </linearGradient>
        <circle
          className="a"
          style={{
            fill: '#00d358',
            stroke: '#000000',
            strokeLinecap: 'round',
          }}
          cx="9.1016"
          cy="15.0698"
          r="4.6016"
        />
        <circle
          className="a"
          style={{
            fill: '#00cc99',
            stroke: '#000000',
            strokeLinecap: 'round',
          }}
          cx="23.9125"
          cy="14.9504"
          r="4.6016"
        />
        <circle
          style={{
            fill: '#0099ff',
            stroke: '#000000',
            strokeLinecap: 'round',
          }}
          className="a"
          cx="38.8984"
          cy="14.9788"
          r="4.6016"
        />
        <path
          className="a"
          style={{
            fill: 'url(#MyGradient)',
            stroke: '#000000',
            strokeLinecap: 'round',
          }}
          d="M5.3366,33.8726V26.4314H42.8283v11.22H35.0258v-3.857c-9.8857.0189-19.8437.03-29.6888.0783Z"
        />
      </svg>
    </SvgIcon>
  );
};

export default ShahidIcon;
