import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from './header';
import { LayoutContainer } from './styles';
import getBanner from '../../api/settings/get-banner';
import Announcement from '../../components/announcement';
import Sidebar from './sidebar';
// import Announcement from '../../components/announcement';

// const headerStyles = {
//   padding: 18,
//   fontSize: 16,
//   height: 56,
//   background: '#34c3ff',
//   color: ' #fff',
//   whiteSpace: 'nowrap',
//   overflow: 'hidden',
// };

const Layout = ({ children }: { children: any }): React.ReactElement<any> => {
  const [bannerData, setBannerData] = useState<any>({});
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  // const [isOpen, setIsOpen] = useState(false);
  const {
    language: { dir },
    // firstName,
  } = useSelector((state: any) => state.user);
  // const isHopes = firstName === 'Amal';

  // useEffect(() => {
  //   if (isHopes) {
  //     setIsOpen(true);
  //   }
  // }, []);
  const fetchBannerData = async () => {
    try {
      const response = await getBanner();
      setBannerData(response.data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    fetchBannerData();
  }, []);

  return (
    <LayoutContainer className={dir}>
      {bannerData && !bannerData?.isDisabled && isBannerOpen && (
        <Announcement onClose={() => setIsBannerOpen(false)}>
          <a
            href={bannerData?.link ? bannerData?.link : '#'}
            {...(bannerData.link
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            {bannerData?.text}
          </a>
        </Announcement>
      )}
      <Header />
      <div
        style={{
          height: 'calc(100% - 50px)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          margin: 0,
        }}
      >
        <Sidebar />

        <div
          style={{
            overflowX: 'scroll',
            padding: '5px 5px 5px 0',
            width: '100%',
          }}
        >
          <div style={{ overflowY: 'scroll' }}>{children}</div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default Layout;
