import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import axios from 'axios';
import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { SnackbarProvider } from 'notistack';
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {
  CssVarsProvider as JoyCssVarsProvider,
  THEME_ID as JOY_THEME_ID,
} from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { NOT_FOUND, UNAUTHORIZED } from './api/server-codes';
import reduxStore from './redux/store';
import theme from './styles/theme';
import Routes from './router';
import { GlobalStyles } from './styles/globalStyles';
import './App.css';
import { LOGIN } from './router/routes';

import 'react-toastify/dist/ReactToastify.css';
import { joyTheme, materialTheme } from './styles/muiThemes';

import { logout as logoutUser } from './redux/slices/user';
import useNetworkStatus from './hooks/useNetworkStatus';
import OfflinePage from './offlinePage';
import AnnouncementMapper from './components/announcement-popup';
import NotificationProcessor from './NotificationProcessor';
import { deleteOldProcesses } from './redux/slices/backgroundProcess';

const queryClient = new QueryClient();
const store = persistStore(reduxStore);
const { dispatch } = reduxStore;

const logout = () => {
  dispatch(logoutUser());
};

const App = (): React.ReactElement<any> => {
  const isOnline = useNetworkStatus();

  useEffect(() => {
    dispatch(deleteOldProcesses());
  }, [dispatch]);

  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={store}>
        <SnackbarProvider maxSnack={3}>
          <MaterialCssVarsProvider
            theme={{ [MATERIAL_THEME_ID]: materialTheme }}
          >
            <JoyCssVarsProvider theme={{ [JOY_THEME_ID]: joyTheme }}>
              <CssBaseline enableColorScheme />

              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <QueryClientProvider client={queryClient}>
                  {isOnline ? (
                    <>
                      <NotificationProcessor />
                      <AnnouncementMapper />
                      <Routes />
                    </>
                  ) : (
                    <OfflinePage />
                  )}
                </QueryClientProvider>
                <ToastContainer position="top-center" autoClose={1500} />
              </ThemeProvider>
            </JoyCssVarsProvider>
          </MaterialCssVarsProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
};

// axios interceptor to logout unauthorized users
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      window.location.pathname !== LOGIN &&
      ((error.response.status === NOT_FOUND &&
        error.response.data.message === 'User not found') ||
        (error.response.status === UNAUTHORIZED &&
          (error.response.data.message === 'User is Banned' ||
            error.response.data.message === 'User should re-login')))
    ) {
      logout();
    }
    return Promise.reject(error);
  },
);

export default App;
