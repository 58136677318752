// @ts-nocheck

import axios from 'axios';
import URLs from '../../config/api-endpoints';
import { getAuthToken } from '../../utils/session';

const changeUserPassword = ({
  oldPassword,
  password,
  passwordConfirm,
}: {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}): any => {
  const jwt = getAuthToken();

  const url = URLs.USER.CHANGE_PASSWORD;

  return axios.post(
    url,
    {
      oldPassword,
      password,
      passwordConfirm,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

export default changeUserPassword;
