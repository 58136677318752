// @ts-nocheck

import { DialogActions } from '@mui/material';
import React from 'react';
import StyledButton from '../button';

const CustomDialogActions = ({
  onClose,
  onConfirm,
  isDisabled,
  isLoading,
  confirmText,
  cancelText,
  isDisabledConfirm,
  isDisabledCancel,
  startText,
}: {
  onClose?: () => void;
  onConfirm?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  confirmText?: string;
  cancelText?: string;
  isDisabledConfirm?: boolean;
  isDisabledCancel?: boolean;
  startText?: string;
}): React.ReactElement => {
  return (
    <DialogActions>
      {startText && (
        <span style={{ marginRight: 'auto', marginLeft: '1rem' }}>
          {startText}
        </span>
      )}
      {isDisabledCancel ? null : (
        <StyledButton
          text={cancelText}
          onClick={onClose}
          isDisabled={isLoading}
          variant="plain"
          color="black"
        />
      )}
      {isDisabledConfirm ? null : (
        <StyledButton
          text={confirmText || 'Confirm'}
          onClick={onConfirm}
          isDisabled={isDisabled}
          isLoading={isLoading}
          color="danger"
          variant="solid"
        />
      )}
    </DialogActions>
  );
};

CustomDialogActions.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  isDisabledConfirm: false,
  isLoading: false,
  isDisabled: false,
  onConfirm: () => null,
  onClose: () => null,
  isDisabledCancel: false,

  startText: '',
};

export default CustomDialogActions;
