import axios, { AxiosPromise } from 'axios';
import URLs from '../../config/api-endpoints';
import { getAuthToken } from '../../utils/session';

const getAllAnnouncements = ({
  dontShowIds,
}: {
  dontShowIds: string[];
}): AxiosPromise => {
  const jwt = getAuthToken();
  const url = URLs.ANNOUNCEMENTS.GET_ALL_ANNOUNCEMENTS;

  return axios.post(
    url,
    { dontShowIds },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

export default getAllAnnouncements;
