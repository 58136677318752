import axios from 'axios';
import URLs from '../../config/api-endpoints';
import { getAuthToken } from '../../utils/session';

const getBanner = () => {
  const token = getAuthToken();
  const url = URLs.SETTINGS.GET_BANNER;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default getBanner;
