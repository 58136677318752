const theme = {
  headerHeight: '55px',
  sidebarWidth: (isCollapsed: boolean) => (isCollapsed ? '50px' : '280px'),
  colors: {
    white: '#fff',
    darkShadow: '#0000000D', // rgba(0, 0, 0, 0.05)
    mediumShadow: '#6e849a33', // rgba(110, 132, 154, 0.2)
    lightShadow: '#9D9DBF33', // rgba(157, 157, 191, 0.2)
    paleShadow: '#e4e6ef',
    boxOuterBorder: '#e9eaed',
    divider: '#e1e1e1',
    disabled: '#e1e1e1',

    primaryColor: '#eb3626',
    primaryColor2: '#f44336',
    primaryColor3: '#f7635c',
    primaryColor4: '#fa8682',
    primaryColor5: '#faa9a7',
    primaryColor6: '#fccfcf',
    primaryColor7: '#fff2f2',

    // activeColor is green
    activeColor: '#45a22F',
    // activeBackgroundColor is green with 10% opacity
    activeBackgroundColor: '#45a11711',
    // inactiveColor is yellow
    inactiveColor: '#FBA01C',
    // inactiveBackgroundColor is yellow with 10% opacity
    inactiveBackgroundColor: '#FBA01C1A',

    bannedColor: '#DB3030',
    expiredColor: '#FBA01C14',

    // bannedBackgroundColor is red with 10% opacity
    bannedBackgroundColor: '#DB30301A',

    infoColor: '#1e88e5',
    infoBackgroundColor: '#E1F7FF',
    errorColor: '#DB3030',
    errorBackgroundColor: '#FCEBE2',

    primaryColorsBlue950: '#020b41',
    primaryColorsBlue900: '#031892',
    primaryColorsBlue850: '#2436a1',
    primaryColorsBlue800: '#0220c1',
    primaryColorsBlue700: '#0023dc',
    primaryColorsBlue600: '#0028ff',
    primaryColorsBlue400: '#6980f9',
    primaryColorsBlue300: '#8ea0ff',
    primaryColorsBlue200: '#c0c9f9',
    primaryColorsBlue100: '#d9dfff',
    primaryColorsBlue50: '#f1f3ff',

    primaryColorsPurple: '#9200b8',
    primaryColorsPurple2: '#a813ce',
    primaryColorsPurple3: '#b727dc',
    primaryColorsPurple4: '#cc43f0',
    primaryColorsPurple5: '#d65af7',
    primaryColorsPurple6: '#e27dfd',
    primaryColorsPurple7: '#e998ff',
    primaryColorsPurple8: '#ecb4fb',
    primaryColorsPurple9: '#f2dff7',

    greyScaleDarkGrey900: '#000000',
    greyScaleDarkGrey850: '#58585d',
    greyScaleDarkGrey800: '#535252',
    greyScaleDarkGrey750: '#505055',
    greyScaleDarkGrey700: '#6b6c6d',
    greyScaleDarkGrey600: '#7f8083',
    greyScaleDarkGrey500: '#a9aaad',
    greyScaleDarkGrey475: '#aeafb2',
    greyScaleDarkGrey450: '#cccccc',
    greyScaleDarkGrey400: '#e0e3e5',
    greyScaleDarkGrey300: '#e8ebee',
    greyScaleDarkGrey100: '#ededed',
    greyScaleDarkGrey50: '#f5f7f9',

    semanticColorsFillsWhite: '#fff',
    semanticColorsFillsGhost: '#f5f6f7',
    semanticColorsWarning: '#fcb21e',
    semanticTextCorrect: '#45a227',
    semanticTextError: '#c2064a',
    semanticColorsError600: '#f92a75',
    semanticTextWarning: '#fba01c',
    sematicCardWarning: '#feebb5',
    semanticTextInformation: '#0785d5',
    semanticCardCorrect: '#dcf1d6',
    semanticCardError: '#fff6f9',
    semanticCardWarning: '#fdf9ef',
    semanticCardInformation: '#ecf7fe',
    semanticIconColor: '#001791',
    semanticSuccessCorrect: '#7cc365',

    primaryCheckboxBorderColor: '#332dc9',
    secondaryCheckboxBorderColor: '#88c5ff',
    errorCheckboxBorderColor: '#fc8fb7',

    transparentMenuBlue: '#03189214',
    transparent_fill_2_primary_48: '#1e68fc7A',
    transparent_fill_2_primary_40: '#1e68fc66',
    transparent_fill_2_primary_32: '#1e68fc52',
    transparent_fill_2_primary_24: '#1e68fc3D',
    transparent_fill_2_primary_16: '#1e68fc29',
    transparent_fill_2_primary_8: '#1e68fc14',

    transparent_fill_7_primary_2_48: '#5000647A',
    transparent_fill_7_primary_2_40: '#50006466',
    transparent_fill_7_primary_2_32: '#50006452',
    transparent_fill_7_primary_2_24: '#5000643D',
    transparent_fill_7_primary_2_16: '#50006429',
    transparent_fill_7_primary_2_8: '#50006414',

    transparent_fill_1_basic_48: '#292a2c7A',
    transparent_fill_1_basic_40: '#292a2c66',
    transparent_fill_1_basic_32: '#292a2c52',
    transparent_fill_1_basic_24: '#292a2c3D',
    transparent_fill_1_basic_16: '#292a2c29', // rgba(41, 42, 44, 0.16)
    transparent_fill_1_basic_8: '#292a2c14', // rgba(41, 42, 44, 0.08)
    transparent_fill_1_basic_0: '#292a2c00',

    // rgba(3, 24, 146, 0.08)
    transparent_fill_3_success_48: '#00897b7A',
    transparent_fill_3_success_40: '#00897b66',
    transparent_fill_3_success_32: '#00897b52',
    transparent_fill_3_success_24: '#00897b3D',
    transparent_fill_3_success_16: '#00897b29',
    transparent_fill_3_success_8: '#00897b14',

    transparent_fill_5_warning_48: '#fcb21e7A',
    transparent_fill_5_warning_40: '#fcb21e66',
    transparent_fill_5_warning_32: '#fcb21e52',
    transparent_fill_5_warning_24: '#fcb21e3D',
    transparent_fill_5_warning_16: '#fcb21e29',
    transparent_fill_5_warning_8: '#fcb21e14',

    transparent_fill_4_info_48: '#01558a7A',
    transparent_fill_4_info_40: '#01558a66',
    transparent_fill_4_info_32: '#01558a52',
    transparent_fill_4_info_24: '#01558a3D',
    transparent_fill_4_info_16: '#01558a29',
    transparent_fill_4_info_8: '#01558a14',

    transparent_fill_6_error_48: '#9a053b7A',
    transparent_fill_6_error_40: '#9a053b66',
    transparent_fill_6_error_32: '#9a053b52',
    transparent_fill_6_error_24: '#9a053b3D',
    transparent_fill_6_error_16: '#9a053b29',
    transparent_fill_6_error_8: '#9a053b14',

    compareSectionBackground: '#0220C114',

    hover_color: 'rgba(161, 154, 175, 0.07)',
    warnBox: '#FDF9EF',
    warnText: '#FBA82E',
  },

  fontSizes: {
    text: {
      regular: '1rem', // 16px
      small: '0.875rem', // 14px
      xsmall: '0.75rem', // 12px
    },
    headers: {
      h1: '2.25re,', // 36px
      h2: '2rem', // 32px
      h3: '1.875rem', // 30px
      h4: '1.625rem', // 26px
      h5: '1.375rem', // 22px
      h6: '1.125rem', // 18px
    },
    buttons: {
      large: '2.063rem', // 17px
      medium: '0.938rem', // 15px
      small: '0.813rem', // 13px
    },
    subtitle: '0.875rem', // 14px
    tableTitle: '0.813rem', // 13px
    caption: '0.75rem', // 12px
    overline: ' 0.625rem', // 10px
    icon: '1.25rem', // 20px
  },

  fonts: {
    regular: 'AvenirRegular',
    black: 'AvenirBlack',
    medium: 'AvenirMedium',
    heavy: 'AvenirHeavy',
    roman: 'AvenirRoman',
    oblique: 'AvenirOblique',
  },

  spacing(x: number) {
    return `${x * 0.25}rem`;
  },

  // Grid Theme
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 3, // rem
    outerMargin: 1.5, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 90, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em // 768px
      md: 64, // em // 1024px
      lg: 75, // em // 1200px
    },
  },
};

export default theme;
