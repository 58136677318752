import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import userSlice from './slices/user';
import processReducer from './slices/backgroundProcess';
import processDialogSlice from './slices/notificationPanel';
import subscriptionLoadingTextSlice from './slices/subscriptionLoadingText';

const userPersistConfig = {
  key: 'user',
  storage,
};

const processesPersistConfig = {
  key: 'processes',
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice),
  processes: persistReducer(processesPersistConfig, processReducer),
  subscriptionLoadingText: subscriptionLoadingTextSlice,
  processDialog: processDialogSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export default store;
