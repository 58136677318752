/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingTextGlobal: '',
  percentageGlobal: 0,
};

const subscriptionLoadingTextSlice = createSlice({
  name: 'subscriptionLoadingText',
  initialState,
  reducers: {
    setUpdateText(state, action) {
      state.loadingTextGlobal = action.payload.loadingTextGlobal;
      state.percentageGlobal = action.payload.percentageGlobal;
    },
  },
});

export const { setUpdateText } = subscriptionLoadingTextSlice.actions;
export default subscriptionLoadingTextSlice.reducer;
